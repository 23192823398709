.static-facilities .tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
.static-facilities .custom-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0;
  }
  
  .DeanSW .custom-table tr {
    margin-bottom: 60px;
  }
  
  .DeanSW .custom-table td {
    margin-right: 5px;
  }
  
  .DeanSW .custom-photo-cell {
    width: 120px;
    text-align: center;
  }
  
  .DeanSW .custom-details-cell {
    padding-left: 10px;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 33px;
  }
  
  .DeanSW .custom-details-cell h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .DeanSW .custom-details-cell .details-wrapper {
    margin-top: 5px;
  }
  
  .DeanSW .custom-details-cell .details-row {
    margin-bottom: 2px;
  }
  
  .DeanSW .custom-details-cell .details-label {
    font-size: 14px;
    font-weight: bold;
  }
  
  .DeanSW .custom-details-cell .details-value {
    font-size: 14px;
    margin-top: 0;
  }
  
  .DeanSW .custom-circle-image {
    width: 110px;
    height: 120px;
  }
  
  .DeanSW .hovered .custom-circle-image {
    transform: scale(1.1);
  }
  
  .DeanSW .columns-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .DeanSW .column {
    flex-basis: calc(50% - 10px);
  }
  
  html h2+* {
    margin-top: 5px !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  .text-left {
    text-align: left !important;
  }
  
  @media (max-width: 767px) {
    .DeanSW .custom-table td {
      margin-right: 0;
    }
  
    .DeanSW .custom-details-cell {
      padding-left: 0;
      margin-top: 20px;
    }
  
    .DeanSW .custom-circle-image {
      width: 80px;
      height: 80px;
    }
  
    .DeanSW .columns-wrapper {
      flex-direction: column;
    }
  
    .DeanSW .column {
      flex-basis: 100%;
      margin-bottom: 30px;
    }
  
    .text-center {
      text-align: center !important;
    }
  }
  /* Tabs Container */
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  /* Individual Tab */
  .tab {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab.active {
    background-color: #007bff;
    color: white;
  }
  
  /* Tablet and Below */
  @media (max-width: 768px) {
    .tabs {
      flex-direction: column;
      align-items: center;
    }
  
    .tab {
      margin-bottom: 10px;
    }
  }
  
.title_arrow {
	position: relative;
	background:rgb(184, 9, 36);
  width: 50%;
}
.title_arrow p {
  color:#ffffff;
  padding:1px 5px 3px 5px;
}
.title_arrow:after {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-left-color: rgb(184, 9, 36);
	border-width: 12px;
	margin-top: -12px;
}

/* .news-scroll-wrapper {
    border: 1px solid black;
    max-width: 400px;
    height: 200px;
    overflow-y: hidden;
    padding: 10px 5px;
    margin: 50px;
}

.slide {
    border: 1px solid black;
    margin: 7px 1px;
    /* transform: translateY(-100px); 
}


.EventsPublication{
     justify-content: center; 
    overflow-x: hidden;
    padding-top: 40px;
}

@media screen and (max-width:780px) {
    .EventsPublication{
        padding: 2vw;
    }
    .subchildEventsPublication{
        margin-top: 10px;
    }
} */





.news-scroll-wrapper {
    border: 1px solid black;
    max-width: 400px;
    height: 200px;
    overflow-y: hidden;
    padding: 10px 5px;
    margin: 50px;
}

.slide {
    border: 1px solid black;
    margin: 7px 1px;
    /* transform: translateY(-100px); */
}


.EventsPublication{
    justify-content: center;
    overflow-x: hidden;
    padding-top: 20px;
    padding-left: 2%;
    padding-right: 2%;
}

@media screen and (max-width:780px) {
    .EventsPublication{
        padding: 2vw;
    }
    .subchildEventsPublication{
        margin-top: 10px;
    }
}
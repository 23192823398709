
.hod-section {
  position: absolute;
  top: 700px; /* Adjust the position from top */
  right: 100px; /* Position from right side */
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ccc; /* Add border */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: scale(1);
}

.hod-section:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  background-color: #f7f7f7;
}

.hod-details {
  margin-left: 20px;
}

.hod-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.hod-name {
  font-size: 16px;
  margin-bottom: 5px;
}

.hod-contact {
  font-size: 14px;
  margin-bottom: 3px;
}

@media (max-width: 992px) {
  .hod-section {
    position: relative;
    top: unset;
    right: unset; /* Reset right position */
    box-shadow: none;
    padding: 0;
    margin-top: 20px; /* Adjust margin from top */
  }
}

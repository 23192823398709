.bar{
/*     height: 514px; */
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    /* width: 500px; */
    display: block;
}

.bar::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  
  /* Track */
  .bar  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  /* Handle */
  .bar::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75);
    border-radius: 10px;
  }



/* Careers.css */

/* Add this CSS to your existing Careers.css file or create a new one */
.border-btn1 {
    border: 1px solid #333;
  }
  
  .border-tab1 {
    border: 1px solid #ccc;
    padding: 10px;
    display: block;
    text-decoration: none;
  }
  
  /* Add the following CSS to ensure the button remains visible on hover */
  .border-btn1:hover {
    border: 1px solid red;
    transition: none; /* Remove transition on hover */
  }
  
  /* Add this CSS to ensure the tab remains visible on hover */
  .border-tab:hover {
    border: 1px solid red;
    transition: none; /* Remove transition on hover */
  }
  
  /* Add the following CSS for the slide-in effect */
  .slide-in {
    animation: slideIn 0.5s ease-out;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Add this CSS for the creative card styling */
  .creative-card1 {
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px;
  }
  
  .creative-card1 .image {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
  }
  
  .creative-card1 .overlay1 {
    border-radius: 0 0 5px 5px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s ease-out;
  }
  
  /* .creative-card:hover .overlay {
    background-color: rgba(255, 255, 255, 0.7);
  } */
  
  .creative-card1 .text {
    text-align: left;
  }
  
  .creative-card1 h4 {
    font-weight: bold;
    color: black;
    margin: 10px 0;
    max-height: 70px;
    overflow: hidden;
  }
  
  .creative-card1 p {
    text-align: justify;
    color: #555;
    font-size: 0.9em;
  }
  
  .creative-card1 .cource-btn {
    border: 1px solid red;
    border-radius: 5px;
    display: inline-block;
    padding: 8px;
    color: red;
    text-decoration: none;
    transition: none;
    text-align: center;
  }
  
   .creative-card1 .cource-btn:hover {
    background-color: red;
    color: white;
  } 
  
/* .scroll-card-wrapper {
    border: 1px solid black;
    border-radius: 10px;
    margin: 10px 0px;
    max-width: 97%;
    min-width: 90%;
    background-color: white;
}

.scroll-card-img {
    padding: 10px;
    border-radius: 10px;
    height: 310px;
    width: inherit;
}

.news-title-news {
    padding: 10px;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9rem !important;
}

.read-more-news {
    padding: 10px;
    text-align: justify;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 0.9rem !important;
}

.read-mores-news {
    padding: 10px;
    text-align: justify;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 0.9rem !important;
}

.read-more:hover {
    cursor: pointer !important;
    font-weight: bolder !important;
    text-decoration: underline !important;
} */


.scroll-card-wrapper {
    border: 1px solid black;
    border-radius: 20px; /* Increase the border-radius for a more rounded shape */
    margin: 10px 0px;
    max-width: 50%;
    min-width: 90%;
    background-color: white;
    perspective: 1000px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  .scroll-card-img {
    padding: 10px;
    border-radius: 10px;
    height: auto;
    width: 100%;
    transform-style: preserve-3d; /* Enable 3D transforms */
    transition: transform 0.3s; /* Add a transition effect */
    backface-visibility: hidden; /* Hide the backface of the image */
    filter: brightness(100%); /* Adjust the brightness as desired */
  }
  
  .scroll-card-wrapper:hover .scroll-card-img {
    transform: scale(1.05) rotateX(10deg); /* Apply a scale and rotate effect on hover */
    filter: brightness(120%); /* Increase brightness on hover */
  }
  
  .news-title-news {
    padding: 10px;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9rem !important;
  }
  
  .read-more-news {
    padding: 10px;
    text-align: justify;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 0.9rem !important;
  }
  
  .read-mores-news {
    padding: 10px;
    text-align: justify;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 0.9rem !important;
  }
  
  .read-more:hover {
    cursor: pointer;
    font-weight: bolder;
    text-decoration: underline;
  }
  
.Navitem {
    margin-left: -10px !important;
    opacity: 0.85;
    width: 16.6rem;
    /* height: 8px; */
}
.headNavitem{
    margin-left: 35px !important;
    opacity: 0.85;
}

.navbar-gov-logo {
    margin-top: -10px !important;
}

.rd-navbar-scroll-align {
    /* justify-content: center; */
    padding-top: 20px !important;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75);
    border-radius: 10px;
  }
/* kdd.css */

/* Specific styles for KeyDocuments */
.key-documents-page {
  /* Add your styles here */
}

.key-documents-page .row {
  display: flex;
  flex-wrap: wrap;
}

.key-documents-page .col-sm-4 {
  flex: 0 0 20%; /* Set the flex basis to 20% for 5 images in a row */
  max-width: 20%;
}

.key-documents-page .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Lighter border color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for box shadow */
}

.key-documents-page .card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly larger box shadow on hover */
}

.key-documents-page .rounded-image {
  display: inline-block;
  width: 100%; /* Full width for the image */
  height: auto;
  border-radius: 8px 8px 0 0; /* Rounded corners only at the top */
}
/* ExamBranchPage.css */

/* Specific styles for ExamBranchPage */
.exam-branch-page {
    text-align: center;
    /* font-family: 'Times New Roman', Times, serif */
  }
  

  .exam-branch-page .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .exam-branch-page .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .exam-branch-page .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .exam-branch-page .mb-4 {
    margin-bottom: 1rem;
  }
  
  .exam-branch-page .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  /* ExamBranchPage.css or your custom styles */
  .exam-small-font {
  font-size: 14px; /* Adjust the font size as needed */
}
  /* .exam-branch-page .hovered {
    /* Add your hover styles here 
  } */
  
  .exam-branch-page .rounded-image {
    display: inline-block;
    max-width: 70%; /* increase and decrease size of box */
    height: auto;
    /* Additional styles for the rounded image if needed */
  }
  
  .exam-branch-page .card-content {
    margin-top: 2px;
  }
  /* Media query for screens with a maximum width of 420 pixels */
@media (max-width: 420px) {
    .exam-branch-page .col-md-3,
    .exam-branch-page .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
/* FacultyPositions.css */

.page {
    background-color: #f5f5f5; /* Set the background color */
}
  
.tabs {
    margin-bottom: 0px; /* Adjust the margin-bottom to reduce the gap between tabs and content */
  
}
  
.tabs button {
    background-color: #4caf50; /* Set the tab background color */
    color: white; /* Set the tab text color */
    margin-right: 10px; /* Adjust the margin-right to reduce the gap between tabs */
}
  
.tabs button.active {
    background-color: #45a049; /* Set the active tab background color */
}
  
.tab-content {
    /* margin-top: -25px;  */
    /* Adjust the margin-top to reduce the gap between tabs and content */
    
}

/* Remove margin and padding for ul and li */
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    margin: 0;
    padding: 0;
}

/* Reduce the gap between rows */
hr {
    margin: -32px 0;
}

element.style {
}
.static-facilities .tabs button.active {
    background-color: #0dcaf0;
    color: #fff;
}



a {
    /* color: #007bff;  */
    text-decoration: none; /* Remove underlines from links */
    transition: color 0.3s ease; /* Add a smooth transition effect */
}

a:hover {
    color: #0056b3; /* Change link color on hover */
    /* text-decoration: underline;  Add underline on hover */
}

.static-facilities .tabs button 

{
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    background-color: #D6EAF8;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s
}
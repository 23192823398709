/* Updated MilestonesTimeline.css */
.milestones-container {
    padding: 20px;
    background-color: #f0f0f0;
}

.milestones-title {
    text-align: center;
    color: blue;
}

.milestones-list {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: center;*/  /* Center align milestones */
    gap: 18px; /* Set gap between milestones */
}

.milestone-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    padding: 3%;
    margin: 0 10px 20px 10px; /* Set margin for spacing */
    /* background-color: #014cf7; */
    border-radius: 20px;
    position: relative;
}



.milestone-box:nth-child(odd) {
    background-color: #2b4675; 
}

.milestone-box:nth-child(even) {
    background-color: #8fc0c4; 
}



.milestone-content {
    margin-top: 10px;
}

.milestone-date {
    font-weight: bold;
    color: #fff;
    font-size: 20px;
}

.milestone-description {
    color: #fff;
}

.arrow {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #787878;
}

.connector {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background-color: #787878;
    width: calc(100% - 335px);
}
/* .paragraph-container {
    max-width: 65%;
    margin-top: 10px;
    text-align: justify;
} */



.nav-tabs .about-tab.active{
    color: #ffffff;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #f19800 !important;
}

.nav-tabs{border-bottom: none !important;}



.tab-title-bar{
    background: #88923e;
      padding: 2px 45px;
      /* position: absolute; */
      top: 0;
     
      left: 0;
      height: 76px;
      display: flex;
      align-items: center;
  }
  
  
  
  
  .aboutus-tabs li.nav-item a{
    font-size: 24px !important;
    color: #828282;
  }
  
  .nav-tabs .about-tab{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

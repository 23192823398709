.news-list {
  overflow-y: scroll;
  height: 400px;
}

.top-btn {
  color: white;
  border: solid 1.5px;
  border-radius: 5px;
  width: 40px;
  margin-top: 10px;
  padding: 10px;
}

.Headeimage {
  width: 140% !important;
  height: 100px !important;
}

body {
  color: #505050 !important;
  font-family: 'Roboto Condensed', sans-serif !important;
}

.text-black {
  color: black !important;
}
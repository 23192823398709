.navbar-div {
  display: flex !important;
  overflow-y: hidden !important;
  overflow-x: auto !important;
  margin: auto !important;
  width: 78% !important;
  position: relative !important;
  top: 2.5rem !important;
  justify-content: space-around !important;
  /* border: solid !important; */
}

.navbar-div::-webkit-scrollbar {
  display: block !important;
}

@media (max-width: 1400px) {
  .navbar-div {
    display: flex !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    margin: auto !important;
    width: 90% !important;
    position: relative !important;
    top: 2.5rem !important;
    justify-content: space-around !important;
    /* flex-wrap: wrap; */
    /* border: solid !important; */
  }

  .navbar-div::-webkit-scrollbar {
    display: block !important;
    height: initial !important;
  }
}

@media (max-width: 1080px) {
  .navbar-div {
    display: flex !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    margin: auto !important;
    width: 95% !important;
    position: relative !important;
    top: 2.5rem !important;
    justify-content: space-around !important;
    flex-wrap: wrap;
    padding-bottom: 25px !important;
    /* border: solid !important; */
  }

  .navbar-div::-webkit-scrollbar {
    display: block !important;
    height: initial !important;
  }
  .btn-custom-css-mobile {
    border: solid !important;
    margin: 5px !important;
    /* padding: 1rem !important; */
    padding: 0.5rem !important;
    font-size: 0.9rem !important;
    /* background-color: rgb(69, 17, 14) !important; */
  }
}

@media (max-width: 660px){
  .navbar-div {
    flex-direction: column;
  }

  .btn-custom-css-mobile{
    width: 60%;
    left: 20%;
    right: 20%;
    padding: 0.25rem !important;
  }

}

.secondary-navbar-button-color{
  background-color: rgb(69, 17, 14) !important;
  border-color:rgb(69, 17, 14) !important;
}

/* .test{
  background-color: rgb(103, 37, 34)
} */
/* Styling for Tabs */
.static-facilities .tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .static-facilities .tabs button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .static-facilities .tabs button.active {
    background-color: #333;
    color: #fff;
  }
  
  .static-facilities .tabs button:hover {
    background-color: #eee;
    color: #555;
  }
  
  /* ... existing styles ... */
  
  .static-facilities .custom-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .static-facilities .custom-table tr {
    margin-bottom: 60px;
  }
  
  .static-facilities .custom-table td {
    margin-right: 5px;
  }
  
  .static-facilities .custom-photo-cell {
    width: 120px;
    text-align: center;
  }
  
  .static-facilities .custom-details-cell {
    flex: 1;
    padding-left: 20px;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .static-facilities .custom-details-cell h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-left: 0px;
  }
  
  .static-facilities .custom-details-cell .details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .static-facilities .custom-details-cell .details-row {
    margin-bottom: 2px;
  }
  
  .static-facilities .custom-details-cell .details-label {
    font-size: 14px;
    font-weight: bold;
  }
  
  .static-facilities .custom-details-cell .details-value {
    font-size: 14px;
    margin-top: 0;
  }
  
  .static-facilities .custom-circle-image {
    width: 110px;
    height: 120px;
  }
  
  .static-facilities .hovered .custom-circle-image {
    transform: scale(1.1);
  }
  
  .static-facilities .columns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items vertically in the center */
  }
  
  .static-facilities .column {
    display: flex; /* Use flex display for column layout */
    flex-basis: calc(50% - 10px);
    align-items: center; /* Align items vertically in the center */
    transition: transform 0.3s ease;
    margin-bottom: 20px;
  }
  
  .static-facilities .hovered .custom-circle-image {
    transform: scale(1.1);
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 767px) {
    .static-facilities .custom-table td {
      margin-right: 5px; /* Revert to original value */
    }
  
    /* No changes to custom-details-cell */
    
    .static-facilities .custom-circle-image {
      width: 110px; /* Revert to original value */
      height: 120px; /* Revert to original value */
    }
  
    /* No changes to columns-wrapper and column */
  }
  

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
h3

.mr-3{margin-right: 1rem!important;}
.ml-3{margin-left: 1rem!important;}

.dept-wrapper p{text-align: justify;}

.dept-wrapper {
  font-size: 1rem;
    font-weight: 400;
    
  }

.dept-wrapper p,.dept-wrapper h3,.dept-wrapper h4,.dept-wrapper h6{
  font-family: 'Work Sans', sans-serif;
}

.dept-wrapper p,
.dept-wrapper tbody {
  /* color: #808080; */
  color: #000;
  line-height: 25.5px;
  /* margin-top: 0.5rem;*/
  margin-bottom: 1rem;
  /* font-family: 'Montserrat', sans-serif !important; */
  /* font-family: 'Roboto', sans-serif; */
  /*font-family: "Work Sans", sans-serif;*/
  /*text-align: justify;*/
  /*font-family: 'Lato', sans-serif;*/
  /*font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";*/
}

.dept-wrapper a:hover {
  text-decoration: none;
}

.dept-wrapper .subtabs{
	padding: 0.5rem 1rem 0rem 1rem !important;
}



.dept-wrapper h6 a{
  
  color: #1029eb !important;
  font-weight: 500;
  line-height: 1;
}

.dept-wrapper h3,.dept-wrapper h4{
  font-size: 24px !important;
  color: #c20315;
  font-weight: 500;
  line-height: 1;
}

.dept-wrapper .navbar {
  padding: 1rem 1rem 0.5rem 1rem;
}

.dept-wrapper .nav-pills-custom .nav-link {
  font-size: 17px;
  color: #000;
  background: #00b6e2;
  position: relative;
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5;
}

.dept-wrapper .nav-pills-custom .nav-link.active {
  color: #fff;
  background: #c20315;
}

@media (min-width: 992px) {
  .dept-wrapper .nav-pills-custom .nav-link::before {
    content: "";
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #c20315;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

.dept-wrapper .nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

.dept-wrapper .nav-pills .nav-link {
  border-radius: 0%;
}

.dept-wrapper .footer {
  background: #c20315 !important;

  text-align: center;
  padding: 0.5rem 0;
}

.dept-wrapper .footer p {
  color: #fff;
  margin-bottom: 0px;
}

.head_of_dept {
  margin: 10% 0%;
}

.dept-wrapper .head_of_dept h6 {
  color: #c20315;
  margin-top: 2%;
}

.dept-wrapper header {
  margin-bottom: 2%;
}

.dept-wrapper .dept-title {
  border-bottom: 0;
}

.dept-wrapper .dept-title a {
  color: #000;

  font-size: 1.5em;
}

.dept-wrapper span.highlight {
  color: #c20315;
  font-weight: 500;
}

.dept-wrapper .tab-content a {
  font-weight: 500;
  color: #000;
}



.dept-wrapper .tab-content p:hover a {
  text-decoration: none;
  color: #c20315;
}

.dept-wrapper hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dept-wrapper .list_items_section li {
  color: #6c757d !important;
}

.dept-wrapper .copyright p {
  text-align: center;
}

/*Cards CSS Start*/

.dept-wrapper .card {
  /*transition:0.5s;*/
  background-color: transparent !important;
  /*cursor: pointer;*/
  /* padding: 5%;*/
  /*border: 2px solid #ddd;*/
  /*box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);*/
  border-radius: 0.5rem;
  margin-bottom: 2%;
  border: 0px;
}

.dept-wrapper .card:hover {
  /* border: 2px solid #c20315;*/
}

.dept-wrapper .tab-content .card {
  padding: 0px;
  border: 0px;
}

.dept-wrapper .card::before,
.dept-wrapper .card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform 0.3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: "";
  pointer-events: none;
}

.dept-wrapper .card::before {
  transform-origin: left top;
}

.dept-wrapper .card::after {
  transform-origin: right bottom;
}

.dept-wrapper .card:hover::before,
.dept-wrapper .card:hover::after,
.dept-wrapper .card:focus::before,
.dept-wrapper .card:focus::after {
  transform: scale3d(1, 1, 1);
}

/*Cards CSS End*/

.dept-wrapper header h1 {
  color: #000;
  font-weight: 600;
  font-size: 36px;
  /* color: #1aa3dd; */
  line-height: 50px;
  margin-bottom: 50px;
  /*margin-top: 40px;*/
}

.dept-wrapper span.bold {
  color: #000;
  font-weight: 600;
}

.dept-wrapper .dropdown button {
  background: no-repeat;
  color: #fff;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
}

.dept-wrapper .dropdown {
  position: relative;
  display: inline-block;
}

.dept-wrapper .dropdown-content {
  padding: 10%;
  text-align: left;

  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dept-wrapper .dropdown-content a {
  font-weight: 600;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dept-wrapper .dropdown-content a:hover {
  color: #c20315;
}

.dept-wrapper .dropdown:hover .dropdown-content {
  display: block;
}

.dept-wrapper .card-block h5 {
  color: #c20315;
  font-size: 20px;
  font-weight: 600;
}

/*.account-details-section p{color: #000;}*/

.dept-wrapper .main-site-section a {
  color: #808080;
}

.dept-wrapper .alumni-services-section a:hover {
  color: #c20315;
  font-weight: 600;
}

.dept-wrapper span.highlight {
  color: #c20315;
  font-weight: 600;
}

.dept-wrapper .event-img img {
  margin-bottom: 5%;
}

/* Faculty profile Css Start */

.dept-wrapper #profile-info {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 3;
}

.dept-wrapper #profile-info .card {
  padding: 0% 6% 0% 0% !important;

  border-radius: 0px;
  box-shadow: none;
  border: 0px;
}

.dept-wrapper #profile-info .card:nth-of-type(3n) {
  margin-right: 0;
}

.dept-wrapper #profile-info img {
  /* width: 100%;
    min-height:100%;*/

  /*width: 192px;
  height: 192px;*/
  border: 5px solid #edc512 !important;
  /* border: 5px solid #ddf2f3; */
  /*padding: 0.25rem;*/
  /* transition:all 0.9s*/
}

.dept-wrapper #profile-info .card:hover img {
  /*transform:scale(1.2,1.2)*/
}

.dept-wrapper #profile-info .data {
  /*position: absolute;*/
  bottom: 0%;
  /* background: #fff; */
  text-align: left;
  width: 100%;
  /*height: 360px;*/
  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
  /*padding: 10px 0px;*/
  box-sizing: border-box;
  /*opacity: 0.8;*/
  /*transition: all 0.4s*/
}

.dept-wrapper #profile-info .data h2 {
  margin: 0 0 5px 0;
  transition: color 0.4s;
}

.dept-wrapper #profile-info .data p {
  /*opacity: 0;*/
  text-align: left;
  transition: all 0.2s;
}

/*#award .card:hover .data p,.card:hover .data a {
    font-size: 12px;
    opacity: 1
  }*/

  .dept-wrapper #profile-info .data h5 {
  font-size: 18px;

  margin-bottom: 0px;
  color: #c20315;
  font-weight: 600;
}

.dept-wrapper #profile-info .data span {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 23.5px;
}

.dept-wrapper #profile-info .data h5 a {
  color: #1029eb ;
  text-decoration: none;
  /*padding:20px;*/
  /*opacity:0*/
}

.dept-wrapper #profile-info .data p.contact-info {
  margin-top: 0.5rem;
}

.dept-wrapper #profile-info .data p.contact-info a {
  color: #808080;
  font-weight: normal;
}

.dept-wrapper #profile-info .data p.contact-info a:hover {
  color: #000;
}

.dept-wrapper #profile-info .data a:hover,
.card:hover h2 {
  color: #000;
}

.dept-wrapper #profile-info .profile-info-individual {
  margin-bottom: 5%;

}

@media (max-width: 799px) {
  .dept-wrapper #profile-info .card {
    padding: 5% 0%;
    width: 100%;
    margin-right: 0;
  }
}

/* Faculty profile Css End */

/*Accordian CSS*/

.dept-wrapper .acc h5.card-title {
  margin-bottom: 0px;
}

.dept-wrapper .acc h3 {
  font-size: 16px;
  background-color: #f2f2f2;
  color: #000;
  padding: 15px;
  margin: 0;
  cursor: pointer;
  position: relative;
}

.dept-wrapper .acc h3:after {
  content: '+';
  position: absolute;
  right: 20px;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.dept-wrapper .acc.active h3:after {
  content: '-';
}

.dept-wrapper .acc .content {
  background-color: #fff;
  display: none;
}

.dept-wrapper .acc .content-inner {
  padding: 5% 5%;
  background: #fdfdfd;
}

.dept-wrapper .acc {
  border-bottom: 1px solid #fff;
}



/* server details card*/
.dept-wrapper .details-card {
/*   background: #ffffff; */
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);*/
  border-radius: 15px;
  padding: 10px 20px 10px 0px;
  /*height: 100%;*/
  display: flex;
  flex-direction: column;
}


/* Academic and laboratory facilities */

.dept-wrapper .academic-programmes .academic-programmes-individual,
.dept-wrapper .laboratory-facilities .laboratory-facilities-individual {
  margin-bottom: 5%;
}

.dept-wrapper .ul-check {
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.5em;
  padding-left: 1.5em;
}

.dept-wrapper .card-title {
  color: #000;
  font-size: 20px;
}

.dept-wrapper .text-black {
  font-weight: 400;

}

.dept-wrapper .item-card {
  /*transition: 0.5s;
  cursor: pointer;*/
}

.dept-wrapper .item-card-title {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
}

.dept-wrapper .item-card-title i {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
  color: #ffa710
}

.dept-wrapper .card-text {
  height: 80px;
}

.dept-wrapper .card::before,
.dept-wrapper .card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
}

.dept-wrapper .card::before {
  transform-origin: left top;
}

.dept-wrapper .card::after {
  transform-origin: right bottom;
}

.dept-wrapper header h3 {
  margin-bottom: 3%;
}


.dept-wrapper p.get_in_touch {
  margin-top: 5%;
  padding: 2% 0%;
  border-top: 1px solid #bc4028;
  border-bottom: 1px solid #bc4028;
}

.dept-wrapper a.more-info-link {
  /*margin-top: 5%;*/
  /*background-color: #eaeaea;*/
  color: #666;
  /*-webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,.25);
                          box-shadow: inset 0 -2px 0 rgba(0,0,0,.25);*/
  /*padding: 12px 20px;*/
}


.dept-wrapper a.more-info-link:hover {

  color: #c20315 !important;
}


.dept-wrapper .vision-mission-section {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 2%;
}


.dept-wrapper .image {
  border: 5px solid #edc512;
  /* padding: 0.25rem;*/
}

/*.image:hover img{
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}*/


.dept-wrapper .academic_group .card {
  margin-bottom: 10%;
}

.dept-wrapper .academic_group a {
  font-weight: normal;
  color: #808080;
}


.dept-wrapper .nav-tabs .nav-item.show .nav-link,
.dept-wrapper .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #c20315;
  border: none;
  margin-bottom: 2px;
}

/*.nav-tabs .nav-link.active::before {
border-color: #0093b7 transparent transparent transparent;
border-style: solid;
border-width: 20px 50px 0px 50px;
content: "";
display: block;


position: absolute;
width: 0px;
top: 40px;
z-index: 10;



}*/

.dept-wrapper .nav-tabs .nav-link.active:after {
  content: '';
  height: 0;
  width: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #c20315;
  left: 0;
  right: 0;
  top: 25%;
  margin: auto;
  position: relative;
  display: block;
}




.dept-wrapper .nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: #00b6e2;
  border: none;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  margin-left: 1%;
  margin-bottom: 2px;
}





.dept-wrapper .nav-tabs {
  border-bottom: none;
}


.dept-wrapper .intro-section {
  margin-bottom: 5%;
}

.dept-wrapper .intro-section p {
  text-align: justify;
}

.dept-wrapper .vision-section,
.dept-wrapper .mission-section {
  margin-top: 2%;
}


.dept-wrapper .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00b6e2;
  border-color: #00b6e2;
}

.dept-wrapper .head_of_dept img {
  border: 5px solid #edc512;
}


.dept-wrapper .notable-alumni .card a img,
.dept-wrapper .std-society .card a img {
  border: 5px solid #ddf2f3;
}

.dept-wrapper .notable-alumni .data h6,
.dept-wrapper .std-society .data h6 {
  margin-bottom: 0px;
}

.dept-wrapper .notable-alumni .data span,
.dept-wrapper .std-society .data span {
  font-weight: 400;
  font-size: 14px;
  color: #808080;

}


.dept-wrapper .std-society .data {
  /* position: absolute; */
  bottom: 0%;
  /* background: #fff; */
  text-align: left;
  width: 100%;
  /* height: 360px; */
  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 0px;
  box-sizing: border-box;
  /* opacity: 0.8; */
  /* transition: all 0.4s; */
}



.dept-wrapper .notable-alumni .data {
  /* position: absolute; */
  bottom: 0%;
  /* background: #fff; */
  text-align: left;
  width: 100%;

  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 0px;
  box-sizing: border-box;
  /* opacity: 0.8; */
  /* transition: all 0.4s; */
}


.dept-wrapper .section .card-4-container div:last-child .card-4 {
  margin-bottom: 0;
}


.dept-wrapper .card-4 {
  /*border-left: 5px solid #00b6e2;*/
  margin: 0 0px 30px 0;

}


.dept-wrapper .card-4 .card-detail {
  /* background: #fff; */
  padding: 0;
  position: relative;
}


.dept-wrapper .card-detail {
  /* background: #0c94e2; */
  padding: 20px;
  color: #808080;
}


.dept-wrapper .card-4 .card-detail .head-bx {
  /*min-height: 20rem;
  max-height: 20rem;*/
  padding: 15px 0px;
}


.dept-wrapper .card-4 .card-detail .info-bx {
  /*padding: 15px 30px;*/
  /*min-height: 14rem;
  max-height: 14rem;*/
}


.dept-wrapper .card-4 .card-detail .info-bx {
  /*background: #f6f6f6;
  padding: 30px 35px;*/
  color: #000000;

}


.dept-wrapper .card-4 .card-detail .more {
  /*background: #00b6e2;*/
  text-align: left;
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}


.dept-wrapper .card-detail .more {
  text-align: right;
  font-size: 14px;
  margin: 10px 0 0;
}


.dept-wrapper .card-4 .card-detail .more a {
  margin-top: 0;
  text-decoration: none;
  font-size: 18px;
  /*padding: 20px 0px;*/

  line-height: 2;
  color: #c20315;
  font-weight: normal;
  /*text-align: center;*/
}


.dept-wrapper .card-4 .card-detail .more a:hover {
  color: #000 !important;
}




/*start phd students css */

.dept-wrapper .phd-std-img img {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  overflow: hidden;
  width: 100%;
}

.dept-wrapper .phd-std-member,
.dept-wrapper .phd-std-img {
  position: relative;
  /*overflow: hidden;*/
}

.dept-wrapper .std-title {
  margin: 10px 0 0px;
      color: #1029eb;
}

.dept-wrapper .overlay {
  background-color: rgba(20,20,20,.7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.dept-wrapper .std-details {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 5%;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.dept-wrapper .std-details p {
  color: #fff;
}

.dept-wrapper .phd-std-img:hover .std-details {
  opacity: 1;
  margin-top: -80px;
}

.dept-wrapper .phd-std-img:hover .overlay {
  opacity: 1;
}

.dept-wrapper .phd-std-member span{
font-size:13px;
}

/*end phd students css */



.alumniTabHide {
  display: none !important;
}

.tab-bold {
  font-weight: 700 !important;
}

.nav-link:hover {
  cursor: pointer;
}


.dept-wrapper .details {
  padding: 5px 0 25px 0;
  margin-bottom: 2%;
}

.dept-wrapper .details span {
 /* font-size: 13px;*/
  color: #c20315;
  font-weight: 600;
}

.dept-wrapper .details h4 {
  line-height:1.2;
  color: #000;
}

ul.awards-ul{list-style-type: disc !important;}
.awards-ul li{margin-bottom: 2%;}







@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .dept-wrapper .intro-section p {
    text-align: left;
  }

  .dept-wrapper .vision-section,
  .dept-wrapper .mission-section {
    margin-top: 10%;
  }


  .dept-wrapper .academic-programmes .academic-programmes-individual,
  .dept-wrapper .laboratory-facilities .laboratory-facilities-individual {
    margin-bottom: 15%;
  }

  .dept-wrapper .head_of_dept {
    border-bottom: 2px solid gray;
  }

  .dept-wrapper h3{    
    font-size: 20px;
    line-height: 1.3;
  }


  p.contact-info{
    font-size: 12px;
    line-height: 1.5;
  }

  .std-details p{
    font-size: 12px;
    line-height: 1.5;
  }

}

.notice-board1 {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c5f2e0;
  border: 1px solid #4CAF50;
  color: rgb(184, 9, 36) !important;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.notice-board1:hover {
  background-color: #F1614B;
  color: white !important;
}

.notice-board1 a{
  color: rgb(184, 9, 36) !important;;
}
.notice-board1 a:hover{
  background-color: #F1614B;
  color: white !important;
}

.listview {
  margin: 0;
  padding: 0;
}
.listview p{
  margin-bottom:0;

}
.listitem {
  position: relative;
  padding-left: 20px; /* Adjust padding to give space for the "bullet" */
  padding-bottom: 0;
  margin: 0;

}

.listitem::before {
  content: '•'; /* Use a bullet or any other character as the list marker */
  position: absolute;
  left: 0;
  top: 0;
  color: #000; /* Bullet color */
  font-size: 1.em; /* Adjust size of the bullet */
  /* line-height: 1; Align bullet with text */
}


.table tr:first-child td {
  font-weight: normal;
}

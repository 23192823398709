/* .popup {
        position: fixed;
        padding: 10px;
        width: 280px;
        left: 50%;
        margin-left: -150px;
        height: 180px;
        top: 50%;
        margin-top: -100px;
        background: #FFF;
        border: 3px solid #F04A49;
        z-index: 20;
    } */

#popup:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: -2;
}

#popup:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: -1;
}

.search_button {
  border: none;
  background-color: inherit;
  color: maroon;
}

.search_button_close{
  border: none;
  background-color: inherit;
  color: maroon;
}

.search_button_close_top{
  border: none;
  background-color: inherit;
  color: maroon;
  display: flex;
  margin-left: 720px;
  margin-top: 10px;
}

.head_seach_text {
  font: 3em "Lato-Bold";
  padding-top:0px;
}
.head_seach_text span{
    color: maroon;
    font-weight: bold;
}

@media (min-width:1800px){
  .head_seach_text{
    display: flex;
    justify-content: center;
  }
  .search_button_close {
    margin-right:22vw ;
  }
  .search_button_close_top{
    margin-top: 15px;
    margin-left: 1106px;
  }
}

.search_all{
    font: 3em/1.1 "Lato-Regular";
    color: #000000;
    background: transparent;
    display: block;
    width: 70%;
    border: none;
    margin-left: 8vw;
    padding-top: 50px;
    margin-bottom: 60px;
    border-bottom: 2px solid rgba(0,0,0,0.29);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.head_content_text{
  font: 1.5em "Lato-Bold";
  font-weight: 600;
  color: maroon;
  display: flex;
    justify-content: flex-start;
    padding-left: 50px;
}

.search_box{
  padding: 30px;
  background-color: beige;
  margin: 5px;

}

.head_seachbox_text{
  font: 1.3em "Lato-Bold";
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.head_seachbox_text span{
  color: maroon;
  font-weight: 600;
}

.searchLocation{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:728px){
  .searchBarLocation{
    display: none;
  }
}

.App-search-box {
  height: 40px;
  width: 500px;
  font-size: 1em;
  margin-top: 10px;
}
.gallery-card {
    width: auto;
    height: 100%;
    background-color: rgb(252, 252, 252);
    border-radius: 0.6rem;
    overflow: hidden;
    padding: 1.2rem;
    padding-bottom: 0.8rem;
    box-shadow: 0 0.24rem 0.48rem 0.12rem rgba(0, 0, 0, 0.15);
    transition: 0.4s;
    text-align: center;
    margin: 1rem;
  }
  
  .gallery-card:hover {
    box-shadow: 0 0.48rem 0.96rem 0.24rem rgba(0, 0, 0, 0.2);
  }
  
  .gallery-card-title {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.3rem;
    color: rgba(0, 0, 0, 0.95);
    margin: 0;
    padding: 0;
  }
  
  .gallery-card-time {
    font-family: "Roboto";
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.75);
    margin: 0;
    padding: 0;
    padding-top: 1rem;
    text-align: right;
  }
  
  .gallery-card-image {
    display: block;
    height: 18rem;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Merriweather&family=Nunito&family=Nunito+Sans:wght@700&family=Roboto:wght@100&display=swap");
  
  .give_merry {
    font-family: "Merriweather", serif;
  }
  
  .quick-li{
    text-decoration: none !important;
  }
  
  .quick-links li {
    border-bottom: 1px solid #555;
    display: inline;
    margin : 0% 2.5%;
    color : #fff;
    font-size : 1.2rem;
  }
  
  .quick-links{
    padding-top:1%;
    padding-bottom: 1%;
  }
  
  .wrappers{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    margin-right: 20px;
    grid-gap: 15px;
  }
  
  .DirectorImage{
    float: left;
    padding-left: 10px;
    margin: 20px;
    height: 270px;
    max-width: 270px;
  }
  .DirectorSay{
    text-align: justify;
    font-size: 1.1rem;
    margin-left: 30px;
    margin-right: 30px;
  }
  .DirectorName{
    margin-left: 30px;
    font-size: 18px;
    color: black;
  }
  .Designation{
    /* margin-left: 30px; */
    font-size: 15px;
    color: black;
  }
  
  .justifys{
    text-align: center;
    font-family: Lato;
    font-weight: 700;
    color: #0d2d62;
    font-size: 1.8rem;
    margin: auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .justifys-h3{
    text-align: center;
    font-family: Lato;
    font-weight: 700;
    color:maroon;
    font-size: 1.5rem;
    margin: auto;
  /*   padding-bottom: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .justifys-h4{
    text-align: center;
    font-family: Lato;
    font-weight: 700;
    color:maroon ;
    font-size: 1.5rem;
    margin: auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .boxes {
    width:100%;
    margin:0 auto;
    display: flex;
    gap: 3vw;
    grid-gap: 1vw;
  }
  .box {
    display:flex;
    width:30%;
    /* height:7em; */
    align-items: center;
    justify-content: center;
    /* margin-left: 6.5vw; */
    height: 200px;
    width: 200px;
    color:#fff;
    border-radius:50%;
  }
  
  .one { 
    background:#fff;
    display: block;
    color: #2b4675;
    padding-top: 67px;
    border: 3px solid #2b4675;
      }
  .two { 
    background:#fff;
    color:#2b4675;
    display: block;
    padding-top: 67px;
    border: 3px solid #2b4675;
  }
  
  
  
  
  @media screen and (max-width:426px) {
    .DirectorImage{
      margin-left: 16.2vw;
      height: 70vw;
      max-width: 270px;
    }
  }
  @media screen and (max-width:376px) {
    .DirectorImage{
      float: left;
      height: 70vw;
      max-width: 270px;
    }
  }
        /* 768px */
  @media screen and (max-width:48em) {
    .wrappers{
      display: block;
      justify-content: center;
      padding: 20px;
    }
   .boxes { 
     display:table;
     width:100%;
     display:block;
  
   }
   .box {
     justify-content: center;
     margin-bottom: 20px;
     width:55%;
     margin-left: 14vw;
  }
  }
  
  .container_tabs {
    box-sizing: content-box;
    max-width: 60em;
    margin: 0 auto 48px auto;
    padding: 12px;
  }
  
  .tabpanel__tab {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .tabpanel__tab:checked + .tabpanel__tablabel {
    background: #8e8e8b;
    color: black;
  }
  
  .tabpanel__tablabel {
    flex: 0 1 auto;
    margin-top: 20px;
    padding: 12px;
    background: #2b4675;
    color: white;
    font-weight: 700;
    margin-left: 10px;
  }
  .tabpanel__tablabel:hover {
    cursor: pointer;
    background: #2b4675;
  }
  
  .tabpanel__panel {
    display: none;
    /* background:#c9c9c9; */
  }
  .tabpanel__panel > *:first-child {
    margin-top: 0;
  }
  .tabpanel__panel > *:last-child {
    margin-bottom: 0;
  }
  .tabpanel__panel > * + * {
    margin-top: 24px;
  }
  
  #tabpanel__tab-1:checked ~ .tabpanel__panels #tabpanel__panel-1,
  #tabpanel__tab-2:checked ~ .tabpanel__panels #tabpanel__panel-2
  {
    display: block;
    position: relative;
    top: 0px;
  }
  
  .media__body > * {
    margin: 0;
  }
  .media__body > p + p,
  .media__body > p + h2 {
    margin-top: 24px;
  }
  
  .media {
    display: flex;
  }
  @media screen and (max-width: 600px) {
    .media {
      flex-direction: column;
      align-items: flex-start;
    }
    .tabpanel__tablabel{
      margin-left: 0;
    }
    .media .media__img {
      margin: 0 auto;
    }
    .media .media__img + .media__body {
      margin: 12px 24px 24px 24px;
    }
  }
  @media screen and (min-width: 601px) {
    .media {
      align-items: flex-start;
    }
    .tabpanel__tablabel{
      margin-left: 0;
    }
    .media .media__img {
      flex: 0 1 auto;
      height: auto;
    }
    .media .media__img + .media__body {
      margin: 24px 105px 24px 18px;
    }
  }
  
  .tabpanel {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  @media screen and (max-width: 600px) {
    .tabpanel {
      flex-direction: column;
    }
    .tabpanel .tabpanel__tablabel {
      padding: 24px 36px;
    }
    .tabpanel .tabpanel__tablabel + .tabpanel__tab + .tabpanel__tablabel {
      margin-top: 6px;
    }
    .tabpanel .tabpanel__tab:checked ~ .tabpanel__panels {
      order: 1;
    }
    .tabpanel .tabpanel__tab:not(:checked) + .tabpanel__tablabel {
      order: initial;
    }
    .tabpanel #tabpanel__tab-1 + .tabpanel__tablabel {
      order: 1;
    }
    .tabpanel #tabpanel__tab-1:checked ~ .tabpanel__panels {
      order: 1;
    }
    .tabpanel #tabpanel__tab-2 + .tabpanel__tablabel {
      order: 2;
    }
    .tabpanel #tabpanel__tab-2:checked ~ .tabpanel__panels {
      order: 2;
    }
    .tabpanel .tabpanel__panel {
      padding: 0 12px 12px 12px;
    }
  }
  @media screen and (min-width: 601px) {
    .tabpanel {
      flex-direction: row;
    }
    .tabpanel .tabpanel__tablabel {
      flex: 0 1 auto;
      padding-left: 24px;
      padding-right: 24px;
    }
    .tabpanel .tabpanel__tablabel + .tabpanel__tab + .tabpanel__tablabel {
      margin-left: 6px;
    }
    .tabpanel .tabpanel__panel {
      padding: 12px;
    }
    .tabpanel .tabpanel__panels {
      flex: 1 1 100%;
    }
  }
  
  /* KALYAN CSS */
  
  .hovered {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    transition: box-shadow 0.3s, transform 0.3s;
  }
  
  .card {
    background-color: #f8f8f8;
    padding: 10px;
  }
  
  .card-content {
    margin-top: 10px;
  }
  
  .card-content h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .card-content a {
    text-decoration: none;
    color: #333;
  }
  
  .card-content a:hover {
    color: #555;
  }
  
  .card:hover .card-content {
    transform: scale(1.05); /* Adjust the zoom level as per your preference */
  }
  
  .card:hover {
   /* background-color: #f0f0f0;*/ /* Adjust the background color on hover */
   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);*/ /* Adjust the box shadow on hover */
    /*transition: background-color 0.3s, box-shadow 0.3s;*/
  }
  
  /* scroll card */
  
  .scroll-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 6px;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .scroll-card-wrapper:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  
  
  .scroll-card-img {
    max-width: auto;
    height: auto;
  }
  
  .news-title-news {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .read-mores-news {
    font-size: 1rem;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .read-more-news {
    font-size: 1rem;
    margin-top: 10px;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  .HorRow{
    border-bottom: 2px solid gray;
    margin-bottom: 5%;
  }
  
  
  .HoR_Details{
    margin:5% 0%;
  }
  .HoR_Details h6{
  color:#5a4e8c ;
  font-weight: 500;
  }
  .tabpanel .tabpanel__panels {
    flex: 1 1 100%;
  }


/* KALYAN CSS */

.hovered {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  transition: box-shadow 0.3s, transform 0.3s;
}

.card {
  background-color: #f8f8f8;
  padding: 10px;
}

.card-content {
  margin-top: 10px;
}

.card-content h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-content a {
  text-decoration: none;
  color: #333;
}

.card-content a:hover {
  color: #555;
}

.card:hover .card-content {
  transform: scale(1.05); /* Adjust the zoom level as per your preference */
}

.card:hover {
 /* background-color: #f0f0f0;*/ /* Adjust the background color on hover */
 /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);*/ /* Adjust the box shadow on hover */
  /*transition: background-color 0.3s, box-shadow 0.3s;*/
}

/* scroll card */

.scroll-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 6px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.scroll-card-wrapper:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}



.scroll-card-img {
  max-width: auto;
  height: auto;
}

.news-title-news {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.read-mores-news {
  font-size: 1rem;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more-news {
  font-size: 1rem;
  margin-top: 10px;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.HorRow{
  border-bottom: 2px solid gray;
  margin-bottom: 5%;
}


.HoR_Details{
  margin:5% 0%;
}
.HoR_Details h6{
color:#5a4e8c ;
font-weight: 500;
}
.HoR_Details p{
  margin-top: 0px;
}


/* ALTERNATE TEXT ANIMATION START*/
.text_1 {
  animation: text1;
}

.text_2 {
  animation: text2;
}

.text_1,
.text_2 {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  animation-duration: 20s;
  animation-timing-function: steps(25, end);
  animation-iteration-count: infinite;
}

.text_1::after,
.text_2::after {
  content: "|";
  position: absolute;
  right: 0;
  animation: caret infinite;
  animation-duration: 1s;
  animation-timing-function: steps(1, end);
}

@keyframes text2 {
  0%,
  50%,
  100% {
    width: 0;
  }
  60%,
  90% {
    width: 43em;
  }
}

@keyframes text1 {
  0%,
  50%,
  100% {
    width: 0;
  }
  10%,
  40% {
    width: 25em;
  }
}

@keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}



.scroll-card-wrapper {
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 10px;
    max-width: 97%;
    min-width: 90%;
    background-color: white;
}

/* .scroll-card-img {
    padding: 10px;
    border-radius: 10px;
    height: 220px;
    width: inherit;
} */
.scroll-card-img {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    height: 220px;
    width: 600px;
    overflow: hidden; /* Hide any overflow beyond the border */
}

.news-title {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    text-align: justify;
    font-size: 0.9rem !important;
}

.read-more {
    text-align: justify;
    font-size: 0.9rem !important;
}

.read-mores {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    text-align: justify;
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 0.9rem !important;
}

.read-more:hover {
    cursor: pointer !important;
    font-weight: bolder !important;
    /* text-decoration: underline !important; */
    text-decoration: none !important;
    color: maroon !important;
}
.news-title:hover{
    color: maroon;
}
.newmarquee {
    top: 1em;
    position: relative;
    box-sizing: border-box;
    animation: newmarquee 8s linear infinite alternate;
    list-style-type: none;
    text-align: center;
}
@keyframes newmarquee {
    0%   { top:   0em }
    100% { top: -10em }
    
}

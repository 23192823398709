.static-facilities .custom-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
}

.static-facilities .custom-table tr {
  margin-bottom: 60px;
}

.static-facilities .custom-table td {
  margin-right: 5px;
}

.static-facilities .custom-photo-cell {
  width: 120px;
  text-align: center;
}

.static-facilities .custom-details-cell {
  flex: 1;
  padding-left: 20px;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.static-facilities .custom-details-cell h2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.static-facilities .custom-details-cell .details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.static-facilities .custom-details-cell .details-row {
  margin-bottom: 2px;
}

.static-facilities .custom-details-cell .details-label {
  font-size: 14px;
  font-weight: bold;
}

.static-facilities .custom-details-cell .details-value {
  font-size: 14px;
  margin-top: 0;
}

.static-facilities .custom-circle-image {
  width: 110px;
  height: 120px;
}

.static-facilities .hovered .custom-circle-image {
  transform: scale(1.1);
}

.static-facilities .columns-wrapper {
  display: flex;
  justify-content: space-between;
}

.static-facilities .column {
  flex-basis: calc(50% - 10px);
}

html h2+* {
  margin-top: 5px !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .static-facilities .custom-table td {
    margin-right: 0; /* Remove the right margin on mobile devices */
  }

  .static-facilities .custom-details-cell {
    padding-left: 0; /* Remove the left padding on mobile devices */
    margin-top: 20px; /* Adjust the margin-top value for better spacing */
  }

  .static-facilities .custom-circle-image {
    width: 80px; /* Adjust the size of the circle image on mobile devices */
    height: 80px;
  }

  .static-facilities .columns-wrapper {
    flex-direction: column; /* Stack columns vertically on mobile devices */
  }

  .static-facilities .column {
    flex-basis: 100%; /* Take up full width on mobile devices */
    margin-bottom: 30px; /* Add spacing between columns on mobile devices */
  }
}
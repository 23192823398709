.overflow-manage {
  overflow-x: auto;
  overflow-y: hidden;
}

.margin-manage-h2 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.margin-manage-h3 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.margin-manage-h4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.margin-manage-h5 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.justify-text-class {
  text-align: justify !important;
  text-justify: inter-word !important;
}


@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

*{
	font-family: 'Lato', sans-serif;
}
.calendar {
margin:.25em 10px 10px 0;
padding-top:5px;
float:left;
width:80px;
font:bold 20px/40px Arial Black,Arial,Helvetica,sans-serif;
text-align:center;
color:#000;
position:relative;
border:1px solid #ededef;
}

.calendar em {
display:block;
font:normal bold 11px/30px Arial,Helvetica,sans-serif;
color:#fff;
background:rgb(184 9 36);
}


.seminar-series{
	margin-bottom:1%;
	padding: 0% 3%;
	
}

.seminar-series img{border-radius: 50%;}

header{
	margin-bottom: 4%;
	color:rgb(184 9 36);
}

.img-padding{
	padding-right:0px;
}
.content h4{font-weight:600;padding-left: 0px;text-align: left;padding-top: 0;}
.content p{font-size:14px !important;margin-top: 0px !important;}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
.img-padding{
	padding-right:15px;
}
}

.content p {
	text-align: justify;
  }
/* Update the CSS code in NewScroll3.css */
.content p {
	text-align: justify;
  }
  

  h4.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
  }
  
  h4.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
  }
  
  h4.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
  }
  
  h4.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
  }
  
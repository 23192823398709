
/* RANKING */
.nirf-ranking-counter [class^=col-] {
    padding: 33px 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.1); }
    .nirf-ranking-counter [class^=col-]:last-child {
      border-right: 0px; }
  
  
  
  
  
  
  /* CARDS */
  
  h2.ccpd_header{font-size: 1.8rem;color: #c20315;font-family: 'Lato', sans-serif;font-weight: bold;}
  .placements-stats h5 a{font-weight: bold !important; color:#c20315 !important ;}
  .placements-stats h5 a:hover{font-weight: bold !important; color:#000 !important ;}
  .placements-stats hr{border-bottom: 1px solid #dedede;}
  
  
  .nitw_ccpd-title {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  color: #444;
  margin-bottom: 60px;
  line-height: 1;
  }
  .nitw_ccpd-card {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* background: #edf2ff; */
  border: 1px solid transparent;
  transition: all 0.6s ease;
  transform: perspective(1000px);
  }
  
  
  .nitw_ccpd-card-top {
  transition: all 0.6s ease;
  border-radius: 15px;
  background: var(--card-color);
  position: relative;
  /* padding: 20px 30px; */
  color: #1b4cc8;
  display: flex;
  flex-direction: column;
  transform: perspective(1000px);
  margin-bottom: 2%;
  }
  h3.nitw_ccpd-card-title {
  font-weight: 700;
  font-size: 24px;
  color: #1b4cc8;
  /* margin-bottom: 80px; */
  }
  
  
  .nitw_ccpd-card-body {
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  flex: 1;
  }
  .nitw_ccpd-card-body p{
  margin-top: 2%;
  }
  
  
  
  /* .nitw_ccpd-card_bg{
  --card-color: #3868e4;
  border: 1px solid  #3868e4;
  } */
  
  
  ul.offers-list{
    list-style-type: disc !important;
  }
  .individual-card{
    margin-bottom: 5%;
  }
  
  
  /* RECRUITMENT */
  
  ul.recruiters-list{
    columns: 3 10rem;
    list-style-type: disc !important;
  }
  
  
  /* Demographics */
  .chart-container {
  width: 100%;
  max-width: 600px;
  height: 500px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  
  
  /* fsfwsefewfrwerwe*/
  
  
  .tile{
  width: 100%;
  
  border-radius:5px;
  box-shadow:0px 2px 3px -1px rgba(151, 171, 187, 0.7);
  float:left;
    transform-style: preserve-3d;
    margin: 10px 5px;
  
  }
  
  .tile .header{
  border-bottom:1px solid #ebeff2;
  padding:19px 0;
  text-align:center;
  color:#59687f;
  font-size:600;
  font-size:19px;	
  position:relative;
  }
  
  
  
  
  .dates{
  border:1px solid #ebeff2;
  border-radius:5px;
  padding:20px 0px;
  margin:10px 20px;
  font-size:16px;
  color:#5aadef;
  font-weight:600;	
  overflow:auto;
  }
  .dates div{
  float:left;
  width:50%;
  text-align:center;
  position:relative;
  }
  .dates strong,
  .stats strong{
  display:block;
  color:#adb8c2;
  
  }
  .dates span{
  width:1px;
  height:40px;
  position:absolute;
  right:0;
  top:0;	
  background:#ebeff2;
  }
  .stats{
  border-top:1px solid #ebeff2;
  background:#f7f8fa;
  overflow:auto;
  padding:15px 0;
  font-size:16px;
  color:#59687f;
  font-weight:600;
  border-radius: 0 0 5px 5px;
  }
  
  
  .stats div:nth-of-type(3){border:none;}
  
  .progress{
  background-color: #dddcdc;
  }
  
  
  
  /* rounded*/
  
  .rounded-progress{
    width: 120px;
    height: 120px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    margin-bottom: 10%;
  }
  .rounded-progress:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
  }
  .rounded-progress > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .rounded-progress .rounded-progress-left{
    left: 0;
  }
  .rounded-progress .rounded-progress-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
  }
  .rounded-progress .rounded-progress-left .rounded-progress-bar{
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  .rounded-progress .rounded-progress-right{
    right: 0;
  }
  .rounded-progress .rounded-progress-right .rounded-progress-bar{
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
  }
  .rounded-progress .rounded-progress-value{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #dddcdc;
    font-size: 18px;
    font-weight: bold;
    color: #0d6efd;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 0%;
    left: 5%;
  }
  .rounded-progress.green .rounded-progress-bar{
    border-color: #2b7238;
  }
  .rounded-progress.green .rounded-progress-left .rounded-progress-bar{
    animation: loading-2 1.5s linear forwards 1.8s;
  }
  .rounded-progress.light .rounded-progress-bar{
    border-color: #2dd897;
  }
  .rounded-progress.light .rounded-progress-left .rounded-progress-bar{
    animation: loading-3 1s linear forwards 1.8s;
  }
  .rounded-progress.pink .rounded-progress-bar{
    border-color: #ed687c;
  }
  .rounded-progress.pink .rounded-progress-left .rounded-progress-bar{
    animation: loading-4 0.4s linear forwards 1.8s;
  }
  .rounded-progress.green .rounded-progress-bar{
    border-color: #135607;
  }
  .rounded-progress.green .rounded-progress-left .rounded-progress-bar{
    animation: loading-5 1.2s linear forwards 1.8s;
  }
  @keyframes loading-1{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
  }
  @keyframes loading-2{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
  }
  @keyframes loading-3{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
  }
  @keyframes loading-4{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
  }
  @keyframes loading-5{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
    
  }
  @media only screen and (max-width: 990px){
    .rounded-progress{ margin-bottom: 20px; }
  }
  
  .progress-seperator{border-right: 2px solid gray;}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
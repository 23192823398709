/* body {
    font-family: "Helvetica Neue", Helvetica, Arial;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    -webkit-font-smoothing: antialiased;

    background: #2b2b2b;
  }
  @media screen and (max-width: 580px) {
    body {
      font-size: 16px;
      line-height: 22px;
    }
  } */  
  .wrapper {
    margin: 0 auto;
    padding: 40px;
    max-width: 800px;
  }
  
  .placement_table {
    margin: 0 0 40px 0;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    display: table;
  }
  @media screen and (max-width: 580px) {
    .placement_table {
      display: block;
    }
  }
  
  .p_row {
    display: table-row;
    background: #f6f6f6;
  }
  .p_row:nth-of-type(odd) {
    background: #e9e9e9;
  }
  .p_row.header {
    font-weight: 900;
    color: #ffffff;
    background: #ea6153;
  }
  .p_row.green {
    background: #27ae60;
  }
  .p_row.blue {
    background: #2980b9;
  }
  @media screen and (max-width: 580px) {
    .p_row {
      padding: 14px 0 7px;
      display: block;
    }
    .p_row.header {
      padding: 0;
      height: 6px;
    }
    .p_row.header .cell {
      display: none;
    }
    .p_row .cell {
      margin-bottom: 10px;
    }
    .p_row .cell:before {
      margin-bottom: 3px;
      content: attr(data-title);
      min-width: 98px;
      font-size: 10px;
      line-height: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #969696;
      display: block;
    }
  }
  
  .cell {
    padding: 6px 12px;
    display: table-cell;
  }
  @media screen and (max-width: 580px) {
    .cell {
      padding: 2px 16px;
      display: block;
    }
  }

.calender{
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width:780px) {
  .calender{
    display: flex;
    justify-content: center;
    margin-left: -10vw;
  }
}
/* AcademicCalendarPage.css */

/* Specific styles for AcademicCalendarPage */
.academic-calendar-page {
    text-align: center;
    background-color: #f5f5f5; /* Light gray background color */
    padding: 20px; /* Add some padding for better spacing */
}

.academic-calendar-page .row {
    display: flex;
    flex-wrap: wrap;
}

.academic-calendar-page .col-sm-6 {
    flex: 0 0 40%; /* Decreased the max-width to 40% */
    max-width: 40%;
}

.academic-calendar-page .col-md-3 {
    flex: 0 0 20%; /* Decreased the max-width to 20% */
    max-width: 20%;
}

.academic-calendar-page .mb-4 {
    margin-bottom: 1rem;
}

.academic-calendar-page .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Lighter border color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for box shadow */
}

.academic-calendar-page .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly larger box shadow on hover */
}

.academic-calendar-page .exam-small-font {
    font-size: 14px;
    color: #777; /* Slightly darker text color */
}

.academic-calendar-page .hovered {
    /* Add your hover styles here */
}

.academic-calendar-page .rounded-image {
    display: inline-block;
    width: 100%; /* Full width for the image */
    height: auto;
    border-radius: 8px 8px 0 0; /* Rounded corners only at the top */
}

.academic-calendar-page .card-content {
    padding: 10px; /* Add padding for better spacing */
}

/* Media query for screens with a maximum width of 420 pixels */
@media (max-width: 420px) {
    .academic-calendar-page .col-md-3,
    .academic-calendar-page .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
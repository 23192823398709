.page {
    font-family: Arial, sans-serif;
    margin: 0 auto;
}

.department-banner {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.department-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
}

.department-card {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.profile-card {
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    text-align: center;
}

.profile-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image-container {
    overflow: hidden;
}

.department-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.profile-card:hover .department-image {
    transform: scale(1.05);
}

/* .card-content {
    padding: 15px;
    background-color: #f9f9f9;
} */

.department-title {
    font-size: 20px;
    font-weight: bold;
    color: #2b4675  ;
    margin: 10px 0;
}

.department-title a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

.department-title a:hover {
    color: #0056b3;
}


.deptviewmore {
    border: 2px solid #2b4675; /* Black border */
    color: #0d6efd; /* Bootstrap primary blue */
    background-color: transparent; /* Transparent background */
    font-weight: bold;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  }

  .deptviewmore:hover {
    background-color: #2b4675; /* Primary blue background on hover */
    color: white !important; /* White text on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
  }

  .deptviewmore:active {
    transform: scale(0.95); /* Slightly shrink on click */
  }

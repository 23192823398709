@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Merriweather&family=Nunito&family=Nunito+Sans:wght@700&family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:100,300,400,600,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@1&family=Merriweather&family=Nunito&family=Nunito+Sans:wght@700&family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.news-list {
  overflow-y: scroll;
  height: 400px;
}

.top-btn {
  color: white;
  border: solid 1.5px;
  border-radius: 5px;
  width: 40px;
  margin-top: 10px;
  padding: 10px;
}

.Headeimage {
  width: 140% !important;
  height: 100px !important;
}

body {
  color: #505050 !important;
  font-family: 'Roboto Condensed', sans-serif !important;
}

.text-black {
  color: black !important;
}
.Navitem {
    margin-left: -10px !important;
    opacity: 0.85;
    width: 16.6rem;
    /* height: 8px; */
}
.headNavitem{
    margin-left: 35px !important;
    opacity: 0.85;
}

.navbar-gov-logo {
    margin-top: -10px !important;
}

.rd-navbar-scroll-align {
    /* justify-content: center; */
    padding-top: 20px !important;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75);
    border-radius: 10px;
  }
/* .popup {
        position: fixed;
        padding: 10px;
        width: 280px;
        left: 50%;
        margin-left: -150px;
        height: 180px;
        top: 50%;
        margin-top: -100px;
        background: #FFF;
        border: 3px solid #F04A49;
        z-index: 20;
    } */

#popup:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: -2;
}

#popup:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: -1;
}

.search_button {
  border: none;
  background-color: inherit;
  color: maroon;
}

.search_button_close{
  border: none;
  background-color: inherit;
  color: maroon;
}

.search_button_close_top{
  border: none;
  background-color: inherit;
  color: maroon;
  display: flex;
  margin-left: 720px;
  margin-top: 10px;
}

.head_seach_text {
  font: 3em "Lato-Bold";
  padding-top:0px;
}
.head_seach_text span{
    color: maroon;
    font-weight: bold;
}

@media (min-width:1800px){
  .head_seach_text{
    display: flex;
    justify-content: center;
  }
  .search_button_close {
    margin-right:22vw ;
  }
  .search_button_close_top{
    margin-top: 15px;
    margin-left: 1106px;
  }
}

.search_all{
    font: 3em/1.1 "Lato-Regular";
    color: #000000;
    background: transparent;
    display: block;
    width: 70%;
    border: none;
    margin-left: 8vw;
    padding-top: 50px;
    margin-bottom: 60px;
    border-bottom: 2px solid rgba(0,0,0,0.29);
    border-radius: 0;
}

.head_content_text{
  font: 1.5em "Lato-Bold";
  font-weight: 600;
  color: maroon;
  display: flex;
    justify-content: flex-start;
    padding-left: 50px;
}

.search_box{
  padding: 30px;
  background-color: beige;
  margin: 5px;

}

.head_seachbox_text{
  font: 1.3em "Lato-Bold";
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.head_seachbox_text span{
  color: maroon;
  font-weight: 600;
}

.searchLocation{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:728px){
  .searchBarLocation{
    display: none;
  }
}

.App-search-box {
  height: 40px;
  width: 500px;
  font-size: 1em;
  margin-top: 10px;
}
.gallery-card {
  width: auto;
  height: 100%;
  background-color: rgb(252, 252, 252);
  border-radius: 0.6rem;
  overflow: hidden;
  padding: 1.2rem;
  padding-bottom: 0.8rem;
  box-shadow: 0 0.24rem 0.48rem 0.12rem rgba(0, 0, 0, 0.15);
  transition: 0.4s;
  text-align: center;
  margin: 1rem;
}

.gallery-card:hover {
  box-shadow: 0 0.48rem 0.96rem 0.24rem rgba(0, 0, 0, 0.2);
}

.gallery-card-title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.95);
  margin: 0;
  padding: 0;
}

.gallery-card-time {
  font-family: "Roboto";
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.75);
  margin: 0;
  padding: 0;
  padding-top: 1rem;
  text-align: right;
}

.gallery-card-image {
  display: block;
  height: 18rem;
  width: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto;
  margin-bottom: 1.5rem;
}

.give_merry {
  font-family: "Merriweather", serif;
}

.quick-li{
  text-decoration: none !important;
}

.quick-links li {
  border-bottom: 1px solid #555;
  display: inline;
  margin : 0% 2.5%;
  color : #fff;
  font-size : 1.2rem;
}

.quick-links{
  padding-top:1%;
  padding-bottom: 1%;
}

.font-change-applicable{
  font-size: medium;
}
/* html ::-webkit-scrollbar {
  display: none;
} */
.announcement-wrapper .text-start {
  overflow: hidden;
}


.announcement-wrapper .text-start:hover {
  overflow-y: scroll;

}

.overflow-manage {
  overflow-x: auto;
  overflow-y: hidden;
}

.margin-manage-h2 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.margin-manage-h3 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.margin-manage-h4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.margin-manage-h5 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.justify-text-class {
  text-align: justify !important;
  text-justify: inter-word !important;
}

.navbar-div {
  display: flex !important;
  overflow-y: hidden !important;
  overflow-x: auto !important;
  margin: auto !important;
  width: 78% !important;
  position: relative !important;
  top: 2.5rem !important;
  justify-content: space-around !important;
  /* border: solid !important; */
}

.navbar-div::-webkit-scrollbar {
  display: block !important;
}

@media (max-width: 1400px) {
  .navbar-div {
    display: flex !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    margin: auto !important;
    width: 90% !important;
    position: relative !important;
    top: 2.5rem !important;
    justify-content: space-around !important;
    /* flex-wrap: wrap; */
    /* border: solid !important; */
  }

  .navbar-div::-webkit-scrollbar {
    display: block !important;
    height: initial !important;
  }
}

@media (max-width: 1080px) {
  .navbar-div {
    display: flex !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    margin: auto !important;
    width: 95% !important;
    position: relative !important;
    top: 2.5rem !important;
    justify-content: space-around !important;
    flex-wrap: wrap;
    padding-bottom: 25px !important;
    /* border: solid !important; */
  }

  .navbar-div::-webkit-scrollbar {
    display: block !important;
    height: initial !important;
  }
  .btn-custom-css-mobile {
    border: solid !important;
    margin: 5px !important;
    /* padding: 1rem !important; */
    padding: 0.5rem !important;
    font-size: 0.9rem !important;
    /* background-color: rgb(69, 17, 14) !important; */
  }
}

@media (max-width: 660px){
  .navbar-div {
    flex-direction: column;
  }

  .btn-custom-css-mobile{
    width: 60%;
    left: 20%;
    right: 20%;
    padding: 0.25rem !important;
  }

}

.secondary-navbar-button-color{
  background-color: rgb(69, 17, 14) !important;
  border-color:rgb(69, 17, 14) !important;
}

/* .test{
  background-color: rgb(103, 37, 34)
} */
.chartsDisplay{
    display: flex;
    justify-content: center;
    /* color: maroon; */
}

@media screen and (max-width:780px) {
    .chartsDisplay{
        display: block;
        padding: 0;
    }
}
@media screen and (min-width:1800px) {
    .chartsDisplay{
        display: inline-block;
        margin-left: 17vw;
    }
}

/* @media screen and (min-width:1800px) {
    .charts{
        width: 15vw;
        height: 15vh;
    }
} */
.learn-more-button {
  color: #0d2d62 !important;
  margin-left: 0 !important;
}

.learn-more-button:hover {
  color: #fff !important;
  margin-left: 0 !important;
}

.notice-board {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c5f2e0;
  border: 1px solid #4CAF50;
  color: rgb(184, 9, 36) !important;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.notice-board:hover {
  background-color: #F1614B;
  color: white !important;
}

.nav-item.btn{
  padding: 5px !important;
}
.textc{
  color: #2b4675;
}


.notice-board-section{
  margin: 2% 0%;
}

.notice-board-section ul li:hover a{
color: #fff;
}

.notice-board-section ul li.btn-outline-danger{
  border-color:#2b4675;
}

.btn-outline-danger:hover{
  background-color: #2b4675;
}

/* .scroll-card-wrapper {
    border: 1px solid black;
    border-radius: 10px;
    margin: 10px 0px;
    max-width: 97%;
    min-width: 90%;
    background-color: white;
}

.scroll-card-img {
    padding: 10px;
    border-radius: 10px;
    height: 310px;
    width: inherit;
}

.news-title-news {
    padding: 10px;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9rem !important;
}

.read-more-news {
    padding: 10px;
    text-align: justify;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 0.9rem !important;
}

.read-mores-news {
    padding: 10px;
    text-align: justify;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 0.9rem !important;
}

.read-more:hover {
    cursor: pointer !important;
    font-weight: bolder !important;
    text-decoration: underline !important;
} */


.scroll-card-wrapper {
    border: 1px solid black;
    border-radius: 20px; /* Increase the border-radius for a more rounded shape */
    margin: 10px 0px;
    max-width: 50%;
    min-width: 90%;
    background-color: white;
    perspective: 1000px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  .scroll-card-img {
    padding: 10px;
    border-radius: 10px;
    height: auto;
    width: 100%;
    transform-style: preserve-3d; /* Enable 3D transforms */
    transition: transform 0.3s; /* Add a transition effect */
    backface-visibility: hidden; /* Hide the backface of the image */
    filter: brightness(100%); /* Adjust the brightness as desired */
  }
  
  .scroll-card-wrapper:hover .scroll-card-img {
    transform: scale(1.05) rotateX(10deg); /* Apply a scale and rotate effect on hover */
    filter: brightness(120%); /* Increase brightness on hover */
  }
  
  .news-title-news {
    padding: 10px;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9rem !important;
  }
  
  .read-more-news {
    padding: 10px;
    text-align: justify;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 0.9rem !important;
  }
  
  .read-mores-news {
    padding: 10px;
    text-align: justify;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 0.9rem !important;
  }
  
  .read-more:hover {
    cursor: pointer;
    font-weight: bolder;
    text-decoration: underline;
  }
  
.scroll-card-wrapper {
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 10px;
    max-width: 97%;
    min-width: 90%;
    background-color: white;
}

/* .scroll-card-img {
    padding: 10px;
    border-radius: 10px;
    height: 220px;
    width: inherit;
} */
.scroll-card-img {
    position: relative;
    padding: 10px;
    border-radius: 10px;
    height: 220px;
    width: 600px;
    overflow: hidden; /* Hide any overflow beyond the border */
}

.news-title {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    text-align: justify;
    font-size: 0.9rem !important;
}

.read-more {
    text-align: justify;
    font-size: 0.9rem !important;
}

.read-mores {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    text-align: justify;
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 0.9rem !important;
}

.read-more:hover {
    cursor: pointer !important;
    font-weight: bolder !important;
    /* text-decoration: underline !important; */
    text-decoration: none !important;
    color: maroon !important;
}
.news-title:hover{
    color: maroon;
}
.newmarquee {
    top: 1em;
    position: relative;
    box-sizing: border-box;
    animation: newmarquee 8s linear infinite alternate;
    list-style-type: none;
    text-align: center;
}
@keyframes newmarquee {
    0%   { top:   0em }
    100% { top: -10em }
    
}

/* .news-scroll-wrapper {
    border: 1px solid black;
    max-width: 400px;
    height: 200px;
    overflow-y: hidden;
    padding: 10px 5px;
    margin: 50px;
}

.slide {
    border: 1px solid black;
    margin: 7px 1px;
    /* transform: translateY(-100px); 
}


.EventsPublication{
     justify-content: center; 
    overflow-x: hidden;
    padding-top: 40px;
}

@media screen and (max-width:780px) {
    .EventsPublication{
        padding: 2vw;
    }
    .subchildEventsPublication{
        margin-top: 10px;
    }
} */





.news-scroll-wrapper {
    border: 1px solid black;
    max-width: 400px;
    height: 200px;
    overflow-y: hidden;
    padding: 10px 5px;
    margin: 50px;
}

.slide {
    border: 1px solid black;
    margin: 7px 1px;
    /* transform: translateY(-100px); */
}


.EventsPublication{
    justify-content: center;
    overflow-x: hidden;
    padding-top: 20px;
    padding-left: 2%;
    padding-right: 2%;
}

@media screen and (max-width:780px) {
    .EventsPublication{
        padding: 2vw;
    }
    .subchildEventsPublication{
        margin-top: 10px;
    }
}
.bar{
/*     height: 514px; */
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    /* width: 500px; */
    display: block;
}

.bar::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  
  /* Track */
  .bar  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  /* Handle */
  .bar::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75);
    border-radius: 10px;
  }




.slide{border: 0px;}

.profile-card{
	background-color: #fff;
	padding: 0px;
    min-height: 415px;
}

.profile-card:hover{
	background-color: #fff;
	
}

.profile-card .card-img-block{
    float:left;
    width:100%;
    height: 100px;
    overflow:hidden;
	background-color: #ebebeb !important;
	padding: 0px !important;
}
.profile-card .card-body{
    position:relative;
	text-align: left;
}
.profile-card .profile {
  border-radius: 50%;
  position: absolute;
  top: -42px;
  left: 15%;
  max-width: 100px;
  border: 3px solid rgba(255, 255, 255, 1);
  transform: translate(-50%, 0%);
}
.profile-card h5{
    font-weight:600;
    color:rgb(184, 9, 36);
}
.profile-card .card-text{text-align: left !important;}
.profile-card .card-text{
    font-weight:300;
    font-size:15px !important;
	text-align: left;
}


.profile-card span {
    font-size:14px;
    background:#fff;
    border:1px solid rgb(184, 9, 36);
    color:rgb(184, 9, 36);
    display:inline-block;
    padding:2px 10px;
    border-radius:15px;
	text-align: left;
    
}

.profile-card .bottom-section{
    position: absolute;
    bottom: 6%;
}

.profile-card .seminar-info-section{
/*  position: absolute;
    top: -15px;
    right: 0%; */
    /* max-width: 100px; */
    /* border: 3px solid rgba(255, 255, 255, 1); */
/*     transform: translate(-10%, 0%); */
}

.profile-card .seminar-info-section p.date-info{
    font-size: 12px !important;
    font-weight: 500;
/*     text-align: center; */
}

.profile-card .bottom-section a{
   font-size: 14px !important;
   font-weight: 600;
   color:rgb(184, 9, 36)
}

.profile-card .bottom-section a:hover{
    font-size: 14px !important;
    font-weight: 600;
    color:#000
 }

.carousel-control-prev-icon,.carousel-control-next-icon{
	background-color: rgba(0,0,0,0.5);
	background-size: 50% 50%;
	width: 1.5rem;
    height: 1.5rem;
	visibility:hidden;
	border-radius: 50%;
}

.carousel-control-prev, .carousel-control-next{
    width: 9%;
}

#carouselExampleControls:hover .carousel-control-next-icon{
	visibility:visible;
}

#carouselExampleControls:hover .carousel-control-prev-icon{
	visibility:visible;
}


*{
	font-family: 'Lato', sans-serif;
}
.calendar {
margin:.25em 10px 10px 0;
padding-top:5px;
float:left;
width:80px;
font:bold 20px/40px Arial Black,Arial,Helvetica,sans-serif;
text-align:center;
color:#000;
position:relative;
border:1px solid #ededef;
}

.calendar em {
display:block;
font:normal bold 11px/30px Arial,Helvetica,sans-serif;
color:#fff;
background:rgb(184 9 36);
}


.seminar-series{
	margin-bottom:1%;
	padding: 0% 3%;
	
}

.seminar-series img{border-radius: 50%;}

header{
	margin-bottom: 4%;
	color:rgb(184 9 36);
}

.img-padding{
	padding-right:0px;
}
.content h4{font-weight:600;padding-left: 0px;text-align: left;padding-top: 0;}
.content p{font-size:14px !important;margin-top: 0px !important;}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
.img-padding{
	padding-right:15px;
}
}

.content p {
	text-align: justify;
  }
/* Update the CSS code in NewScroll3.css */
.content p {
	text-align: justify;
  }
  

  h4.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
  }
  
  h4.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
  }
  
  h4.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
  }
  
  h4.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
  }
  
.gallery-card {
    width: auto;
    height: 100%;
    background-color: rgb(252, 252, 252);
    border-radius: 0.6rem;
    overflow: hidden;
    padding: 1.2rem;
    padding-bottom: 0.8rem;
    box-shadow: 0 0.24rem 0.48rem 0.12rem rgba(0, 0, 0, 0.15);
    transition: 0.4s;
    text-align: center;
    margin: 1rem;
  }
  
  .gallery-card:hover {
    box-shadow: 0 0.48rem 0.96rem 0.24rem rgba(0, 0, 0, 0.2);
  }
  
  .gallery-card-title {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.3rem;
    color: rgba(0, 0, 0, 0.95);
    margin: 0;
    padding: 0;
  }
  
  .gallery-card-time {
    font-family: "Roboto";
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.75);
    margin: 0;
    padding: 0;
    padding-top: 1rem;
    text-align: right;
  }
  
  .gallery-card-image {
    display: block;
    height: 18rem;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  
  .give_merry {
    font-family: "Merriweather", serif;
  }
  
  .quick-li{
    text-decoration: none !important;
  }
  
  .quick-links li {
    border-bottom: 1px solid #555;
    display: inline;
    margin : 0% 2.5%;
    color : #fff;
    font-size : 1.2rem;
  }
  
  .quick-links{
    padding-top:1%;
    padding-bottom: 1%;
  }
  
  .wrappers{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    margin-right: 20px;
    grid-gap: 15px;
  }
  
  .DirectorImage{
    float: left;
    padding-left: 10px;
    margin: 20px;
    height: 270px;
    max-width: 270px;
  }
  .DirectorSay{
    text-align: justify;
    font-size: 1.1rem;
    margin-left: 30px;
    margin-right: 30px;
  }
  .DirectorName{
    margin-left: 30px;
    font-size: 18px;
    color: black;
  }
  .Designation{
    /* margin-left: 30px; */
    font-size: 15px;
    color: black;
  }
  
  .justifys{
    text-align: center;
    font-family: Lato;
    font-weight: 700;
    color: #0d2d62;
    font-size: 1.8rem;
    margin: auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .justifys-h3{
    text-align: center;
    font-family: Lato;
    font-weight: 700;
    color:maroon;
    font-size: 1.5rem;
    margin: auto;
  /*   padding-bottom: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .justifys-h4{
    text-align: center;
    font-family: Lato;
    font-weight: 700;
    color:maroon ;
    font-size: 1.5rem;
    margin: auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .boxes {
    width:100%;
    margin:0 auto;
    display: flex;
    grid-gap: 3vw;
    gap: 3vw;
    grid-gap: 1vw;
  }
  .box {
    display:flex;
    width:30%;
    /* height:7em; */
    align-items: center;
    justify-content: center;
    /* margin-left: 6.5vw; */
    height: 200px;
    width: 200px;
    color:#fff;
    border-radius:50%;
  }
  
  .one { 
    background:#fff;
    display: block;
    color: #2b4675;
    padding-top: 67px;
    border: 3px solid #2b4675;
      }
  .two { 
    background:#fff;
    color:#2b4675;
    display: block;
    padding-top: 67px;
    border: 3px solid #2b4675;
  }
  
  
  
  
  @media screen and (max-width:426px) {
    .DirectorImage{
      margin-left: 16.2vw;
      height: 70vw;
      max-width: 270px;
    }
  }
  @media screen and (max-width:376px) {
    .DirectorImage{
      float: left;
      height: 70vw;
      max-width: 270px;
    }
  }
        /* 768px */
  @media screen and (max-width:48em) {
    .wrappers{
      display: block;
      justify-content: center;
      padding: 20px;
    }
   .boxes { 
     display:table;
     width:100%;
     display:block;
  
   }
   .box {
     justify-content: center;
     margin-bottom: 20px;
     width:55%;
     margin-left: 14vw;
  }
  }
  
  .container_tabs {
    box-sizing: content-box;
    max-width: 60em;
    margin: 0 auto 48px auto;
    padding: 12px;
  }
  
  .tabpanel__tab {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .tabpanel__tab:checked + .tabpanel__tablabel {
    background: #8e8e8b;
    color: black;
  }
  
  .tabpanel__tablabel {
    flex: 0 1 auto;
    margin-top: 20px;
    padding: 12px;
    background: #2b4675;
    color: white;
    font-weight: 700;
    margin-left: 10px;
  }
  .tabpanel__tablabel:hover {
    cursor: pointer;
    background: #2b4675;
  }
  
  .tabpanel__panel {
    display: none;
    /* background:#c9c9c9; */
  }
  .tabpanel__panel > *:first-child {
    margin-top: 0;
  }
  .tabpanel__panel > *:last-child {
    margin-bottom: 0;
  }
  .tabpanel__panel > * + * {
    margin-top: 24px;
  }
  
  #tabpanel__tab-1:checked ~ .tabpanel__panels #tabpanel__panel-1,
  #tabpanel__tab-2:checked ~ .tabpanel__panels #tabpanel__panel-2
  {
    display: block;
    position: relative;
    top: 0px;
  }
  
  .media__body > * {
    margin: 0;
  }
  .media__body > p + p,
  .media__body > p + h2 {
    margin-top: 24px;
  }
  
  .media {
    display: flex;
  }
  @media screen and (max-width: 600px) {
    .media {
      flex-direction: column;
      align-items: flex-start;
    }
    .tabpanel__tablabel{
      margin-left: 0;
    }
    .media .media__img {
      margin: 0 auto;
    }
    .media .media__img + .media__body {
      margin: 12px 24px 24px 24px;
    }
  }
  @media screen and (min-width: 601px) {
    .media {
      align-items: flex-start;
    }
    .tabpanel__tablabel{
      margin-left: 0;
    }
    .media .media__img {
      flex: 0 1 auto;
      height: auto;
    }
    .media .media__img + .media__body {
      margin: 24px 105px 24px 18px;
    }
  }
  
  .tabpanel {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  @media screen and (max-width: 600px) {
    .tabpanel {
      flex-direction: column;
    }
    .tabpanel .tabpanel__tablabel {
      padding: 24px 36px;
    }
    .tabpanel .tabpanel__tablabel + .tabpanel__tab + .tabpanel__tablabel {
      margin-top: 6px;
    }
    .tabpanel .tabpanel__tab:checked ~ .tabpanel__panels {
      order: 1;
    }
    .tabpanel .tabpanel__tab:not(:checked) + .tabpanel__tablabel {
      order: initial;
    }
    .tabpanel #tabpanel__tab-1 + .tabpanel__tablabel {
      order: 1;
    }
    .tabpanel #tabpanel__tab-1:checked ~ .tabpanel__panels {
      order: 1;
    }
    .tabpanel #tabpanel__tab-2 + .tabpanel__tablabel {
      order: 2;
    }
    .tabpanel #tabpanel__tab-2:checked ~ .tabpanel__panels {
      order: 2;
    }
    .tabpanel .tabpanel__panel {
      padding: 0 12px 12px 12px;
    }
  }
  @media screen and (min-width: 601px) {
    .tabpanel {
      flex-direction: row;
    }
    .tabpanel .tabpanel__tablabel {
      flex: 0 1 auto;
      padding-left: 24px;
      padding-right: 24px;
    }
    .tabpanel .tabpanel__tablabel + .tabpanel__tab + .tabpanel__tablabel {
      margin-left: 6px;
    }
    .tabpanel .tabpanel__panel {
      padding: 12px;
    }
    .tabpanel .tabpanel__panels {
      flex: 1 1 100%;
    }
  }
  
  /* KALYAN CSS */
  
  .hovered {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    transition: box-shadow 0.3s, transform 0.3s;
  }
  
  .card {
    background-color: #f8f8f8;
    padding: 10px;
  }
  
  .card-content {
    margin-top: 10px;
  }
  
  .card-content h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .card-content a {
    text-decoration: none;
    color: #333;
  }
  
  .card-content a:hover {
    color: #555;
  }
  
  .card:hover .card-content {
    transform: scale(1.05); /* Adjust the zoom level as per your preference */
  }
  
  .card:hover {
   /* background-color: #f0f0f0;*/ /* Adjust the background color on hover */
   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);*/ /* Adjust the box shadow on hover */
    /*transition: background-color 0.3s, box-shadow 0.3s;*/
  }
  
  /* scroll card */
  
  .scroll-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 6px;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .scroll-card-wrapper:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  
  
  .scroll-card-img {
    max-width: auto;
    height: auto;
  }
  
  .news-title-news {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .read-mores-news {
    font-size: 1rem;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .read-more-news {
    font-size: 1rem;
    margin-top: 10px;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  .HorRow{
    border-bottom: 2px solid gray;
    margin-bottom: 5%;
  }
  
  
  .HoR_Details{
    margin:5% 0%;
  }
  .HoR_Details h6{
  color:#5a4e8c ;
  font-weight: 500;
  }
  .tabpanel .tabpanel__panels {
    flex: 1 1 100%;
  }


/* KALYAN CSS */

.hovered {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  transition: box-shadow 0.3s, transform 0.3s;
}

.card {
  background-color: #f8f8f8;
  padding: 10px;
}

.card-content {
  margin-top: 10px;
}

.card-content h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-content a {
  text-decoration: none;
  color: #333;
}

.card-content a:hover {
  color: #555;
}

.card:hover .card-content {
  transform: scale(1.05); /* Adjust the zoom level as per your preference */
}

.card:hover {
 /* background-color: #f0f0f0;*/ /* Adjust the background color on hover */
 /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);*/ /* Adjust the box shadow on hover */
  /*transition: background-color 0.3s, box-shadow 0.3s;*/
}

/* scroll card */

.scroll-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 6px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.scroll-card-wrapper:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}



.scroll-card-img {
  max-width: auto;
  height: auto;
}

.news-title-news {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.read-mores-news {
  font-size: 1rem;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more-news {
  font-size: 1rem;
  margin-top: 10px;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.HorRow{
  border-bottom: 2px solid gray;
  margin-bottom: 5%;
}


.HoR_Details{
  margin:5% 0%;
}
.HoR_Details h6{
color:#5a4e8c ;
font-weight: 500;
}
.HoR_Details p{
  margin-top: 0px;
}


/* ALTERNATE TEXT ANIMATION START*/
.text_1 {
  animation: text1;
}

.text_2 {
  animation: text2;
}

.text_1,
.text_2 {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  animation-duration: 20s;
  animation-timing-function: steps(25, end);
  animation-iteration-count: infinite;
}

.text_1::after,
.text_2::after {
  content: "|";
  position: absolute;
  right: 0;
  animation: caret infinite;
  animation-duration: 1s;
  animation-timing-function: steps(1, end);
}

@keyframes text2 {
  0%,
  50%,
  100% {
    width: 0;
  }
  60%,
  90% {
    width: 43em;
  }
}

@keyframes text1 {
  0%,
  50%,
  100% {
    width: 0;
  }
  10%,
  40% {
    width: 25em;
  }
}

@keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}




.hod-section {
  position: absolute;
  top: 700px; /* Adjust the position from top */
  right: 100px; /* Position from right side */
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ccc; /* Add border */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: scale(1);
}

.hod-section:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  background-color: #f7f7f7;
}

.hod-details {
  margin-left: 20px;
}

.hod-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.hod-name {
  font-size: 16px;
  margin-bottom: 5px;
}

.hod-contact {
  font-size: 14px;
  margin-bottom: 3px;
}

@media (max-width: 992px) {
  .hod-section {
    position: relative;
    top: unset;
    right: unset; /* Reset right position */
    box-shadow: none;
    padding: 0;
    margin-top: 20px; /* Adjust margin from top */
  }
}

/* body {
    font-family: "Helvetica Neue", Helvetica, Arial;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #3b3b3b;
    -webkit-font-smoothing: antialiased;

    background: #2b2b2b;
  }
  @media screen and (max-width: 580px) {
    body {
      font-size: 16px;
      line-height: 22px;
    }
  } */  
  .wrapper {
    margin: 0 auto;
    padding: 40px;
    max-width: 800px;
  }
  
  .placement_table {
    margin: 0 0 40px 0;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    display: table;
  }
  @media screen and (max-width: 580px) {
    .placement_table {
      display: block;
    }
  }
  
  .p_row {
    display: table-row;
    background: #f6f6f6;
  }
  .p_row:nth-of-type(odd) {
    background: #e9e9e9;
  }
  .p_row.header {
    font-weight: 900;
    color: #ffffff;
    background: #ea6153;
  }
  .p_row.green {
    background: #27ae60;
  }
  .p_row.blue {
    background: #2980b9;
  }
  @media screen and (max-width: 580px) {
    .p_row {
      padding: 14px 0 7px;
      display: block;
    }
    .p_row.header {
      padding: 0;
      height: 6px;
    }
    .p_row.header .cell {
      display: none;
    }
    .p_row .cell {
      margin-bottom: 10px;
    }
    .p_row .cell:before {
      margin-bottom: 3px;
      content: attr(data-title);
      min-width: 98px;
      font-size: 10px;
      line-height: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #969696;
      display: block;
    }
  }
  
  .cell {
    padding: 6px 12px;
    display: table-cell;
  }
  @media screen and (max-width: 580px) {
    .cell {
      padding: 2px 16px;
      display: block;
    }
  }

.calender{
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width:780px) {
  .calender{
    display: flex;
    justify-content: center;
    margin-left: -10vw;
  }
}
/* header */

/* header/copyright link */
.link {
  text-decoration: none;
  color: #55acee;
  border-bottom: 2px dotted #55acee;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  cursor: url(http://cur.cursors-4u.net/symbols/sym-1/sym46.cur), auto;
}

.link:hover {
  color: #2ecc71;
  border-bottom: 2px dotted #2ecc71;
}

/* button div */
#buttons {
  padding-top: 50px;
  text-align: center;
}

/* start da css for da buttons */
.btn {
  border-radius: 5px;
  padding: 15px 25px;
  font-size: 22px;
  text-decoration: none;
  margin: 20px;
  margin-top: -20px;
  margin-bottom: 40px;
  color: #fff;
  position: relative;
  display: inline-block;
}

.btn:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px;
}

.blue {
  background-color: #55acee;
  box-shadow: 0px 5px 0px 0px #3c93d5;
}

.blue:hover {
  background-color: #6fc6ff;
}

.green {
  background-color: #2ecc71;
  box-shadow: 0px 5px 0px 0px #15b358;
}

.green:hover {
  background-color: #48e68b;
}

.red {
  background-color: #e74c3c;
  box-shadow: 0px 5px 0px 0px #ce3323;
}

.red:hover {
  background-color: #ff6656;
}

.page {
    font-family: Arial, sans-serif;
    margin: 0 auto;
}

.department-banner {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.department-container {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
}

.department-card {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.profile-card {
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    text-align: center;
}

.profile-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image-container {
    overflow: hidden;
}

.department-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.profile-card:hover .department-image {
    transform: scale(1.05);
}

/* .card-content {
    padding: 15px;
    background-color: #f9f9f9;
} */

.department-title {
    font-size: 20px;
    font-weight: bold;
    color: #2b4675  ;
    margin: 10px 0;
}

.department-title a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

.department-title a:hover {
    color: #0056b3;
}


.deptviewmore {
    border: 2px solid #2b4675; /* Black border */
    color: #0d6efd; /* Bootstrap primary blue */
    background-color: transparent; /* Transparent background */
    font-weight: bold;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  }

  .deptviewmore:hover {
    background-color: #2b4675; /* Primary blue background on hover */
    color: white !important; /* White text on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
  }

  .deptviewmore:active {
    transform: scale(0.95); /* Slightly shrink on click */
  }

.static-facilities .custom-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
}

.static-facilities .custom-table tr {
  margin-bottom: 60px;
}

.static-facilities .custom-table td {
  margin-right: 5px;
}

.static-facilities .custom-photo-cell {
  width: 120px;
  text-align: center;
}

.static-facilities .custom-details-cell {
  flex: 1 1;
  padding-left: 20px;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.static-facilities .custom-details-cell h2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.static-facilities .custom-details-cell .details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.static-facilities .custom-details-cell .details-row {
  margin-bottom: 2px;
}

.static-facilities .custom-details-cell .details-label {
  font-size: 14px;
  font-weight: bold;
}

.static-facilities .custom-details-cell .details-value {
  font-size: 14px;
  margin-top: 0;
}

.static-facilities .custom-circle-image {
  width: 110px;
  height: 120px;
}

.static-facilities .hovered .custom-circle-image {
  transform: scale(1.1);
}

.static-facilities .columns-wrapper {
  display: flex;
  justify-content: space-between;
}

.static-facilities .column {
  flex-basis: calc(50% - 10px);
}

html h2+* {
  margin-top: 5px !important;
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .static-facilities .custom-table td {
    margin-right: 0; /* Remove the right margin on mobile devices */
  }

  .static-facilities .custom-details-cell {
    padding-left: 0; /* Remove the left padding on mobile devices */
    margin-top: 20px; /* Adjust the margin-top value for better spacing */
  }

  .static-facilities .custom-circle-image {
    width: 80px; /* Adjust the size of the circle image on mobile devices */
    height: 80px;
  }

  .static-facilities .columns-wrapper {
    flex-direction: column; /* Stack columns vertically on mobile devices */
  }

  .static-facilities .column {
    flex-basis: 100%; /* Take up full width on mobile devices */
    margin-bottom: 30px; /* Add spacing between columns on mobile devices */
  }
}
/* Styling for Tabs */
.static-facilities .tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .static-facilities .tabs button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .static-facilities .tabs button.active {
    background-color: #333;
    color: #fff;
  }
  
  .static-facilities .tabs button:hover {
    background-color: #eee;
    color: #555;
  }
  
  /* ... existing styles ... */
  
  .static-facilities .custom-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .static-facilities .custom-table tr {
    margin-bottom: 60px;
  }
  
  .static-facilities .custom-table td {
    margin-right: 5px;
  }
  
  .static-facilities .custom-photo-cell {
    width: 120px;
    text-align: center;
  }
  
  .static-facilities .custom-details-cell {
    flex: 1 1;
    padding-left: 20px;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .static-facilities .custom-details-cell h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-left: 0px;
  }
  
  .static-facilities .custom-details-cell .details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .static-facilities .custom-details-cell .details-row {
    margin-bottom: 2px;
  }
  
  .static-facilities .custom-details-cell .details-label {
    font-size: 14px;
    font-weight: bold;
  }
  
  .static-facilities .custom-details-cell .details-value {
    font-size: 14px;
    margin-top: 0;
  }
  
  .static-facilities .custom-circle-image {
    width: 110px;
    height: 120px;
  }
  
  .static-facilities .hovered .custom-circle-image {
    transform: scale(1.1);
  }
  
  .static-facilities .columns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items vertically in the center */
  }
  
  .static-facilities .column {
    display: flex; /* Use flex display for column layout */
    flex-basis: calc(50% - 10px);
    align-items: center; /* Align items vertically in the center */
    transition: transform 0.3s ease;
    margin-bottom: 20px;
  }
  
  .static-facilities .hovered .custom-circle-image {
    transform: scale(1.1);
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 767px) {
    .static-facilities .custom-table td {
      margin-right: 5px; /* Revert to original value */
    }
  
    /* No changes to custom-details-cell */
    
    .static-facilities .custom-circle-image {
      width: 110px; /* Revert to original value */
      height: 120px; /* Revert to original value */
    }
  
    /* No changes to columns-wrapper and column */
  }
  
.static-facilities .tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
.static-facilities .custom-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0;
  }
  
  .DeanSW .custom-table tr {
    margin-bottom: 60px;
  }
  
  .DeanSW .custom-table td {
    margin-right: 5px;
  }
  
  .DeanSW .custom-photo-cell {
    width: 120px;
    text-align: center;
  }
  
  .DeanSW .custom-details-cell {
    padding-left: 10px;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 33px;
  }
  
  .DeanSW .custom-details-cell h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .DeanSW .custom-details-cell .details-wrapper {
    margin-top: 5px;
  }
  
  .DeanSW .custom-details-cell .details-row {
    margin-bottom: 2px;
  }
  
  .DeanSW .custom-details-cell .details-label {
    font-size: 14px;
    font-weight: bold;
  }
  
  .DeanSW .custom-details-cell .details-value {
    font-size: 14px;
    margin-top: 0;
  }
  
  .DeanSW .custom-circle-image {
    width: 110px;
    height: 120px;
  }
  
  .DeanSW .hovered .custom-circle-image {
    transform: scale(1.1);
  }
  
  .DeanSW .columns-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .DeanSW .column {
    flex-basis: calc(50% - 10px);
  }
  
  html h2+* {
    margin-top: 5px !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  .text-left {
    text-align: left !important;
  }
  
  @media (max-width: 767px) {
    .DeanSW .custom-table td {
      margin-right: 0;
    }
  
    .DeanSW .custom-details-cell {
      padding-left: 0;
      margin-top: 20px;
    }
  
    .DeanSW .custom-circle-image {
      width: 80px;
      height: 80px;
    }
  
    .DeanSW .columns-wrapper {
      flex-direction: column;
    }
  
    .DeanSW .column {
      flex-basis: 100%;
      margin-bottom: 30px;
    }
  
    .text-center {
      text-align: center !important;
    }
  }
  /* Tabs Container */
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  /* Individual Tab */
  .tab {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tab.active {
    background-color: #007bff;
    color: white;
  }
  
  /* Tablet and Below */
  @media (max-width: 768px) {
    .tabs {
      flex-direction: column;
      align-items: center;
    }
  
    .tab {
      margin-bottom: 10px;
    }
  }
  
.title_arrow {
	position: relative;
	background:rgb(184, 9, 36);
  width: 50%;
}
.title_arrow p {
  color:#ffffff;
  padding:1px 5px 3px 5px;
}
.title_arrow:after {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-left-color: rgb(184, 9, 36);
	border-width: 12px;
	margin-top: -12px;
}


h3

.mr-3{margin-right: 1rem!important;}
.ml-3{margin-left: 1rem!important;}

.dept-wrapper p{text-align: justify;}

.dept-wrapper {
  font-size: 1rem;
    font-weight: 400;
    
  }

.dept-wrapper p,.dept-wrapper h3,.dept-wrapper h4,.dept-wrapper h6{
  font-family: 'Work Sans', sans-serif;
}

.dept-wrapper p,
.dept-wrapper tbody {
  /* color: #808080; */
  color: #000;
  line-height: 25.5px;
  /* margin-top: 0.5rem;*/
  margin-bottom: 1rem;
  /* font-family: 'Montserrat', sans-serif !important; */
  /* font-family: 'Roboto', sans-serif; */
  /*font-family: "Work Sans", sans-serif;*/
  /*text-align: justify;*/
  /*font-family: 'Lato', sans-serif;*/
  /*font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";*/
}

.dept-wrapper a:hover {
  text-decoration: none;
}

.dept-wrapper .subtabs{
	padding: 0.5rem 1rem 0rem 1rem !important;
}



.dept-wrapper h6 a{
  
  color: #1029eb !important;
  font-weight: 500;
  line-height: 1;
}

.dept-wrapper h3,.dept-wrapper h4{
  font-size: 24px !important;
  color: #c20315;
  font-weight: 500;
  line-height: 1;
}

.dept-wrapper .navbar {
  padding: 1rem 1rem 0.5rem 1rem;
}

.dept-wrapper .nav-pills-custom .nav-link {
  font-size: 17px;
  color: #000;
  background: #00b6e2;
  position: relative;
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5;
}

.dept-wrapper .nav-pills-custom .nav-link.active {
  color: #fff;
  background: #c20315;
}

@media (min-width: 992px) {
  .dept-wrapper .nav-pills-custom .nav-link::before {
    content: "";
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #c20315;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

.dept-wrapper .nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

.dept-wrapper .nav-pills .nav-link {
  border-radius: 0%;
}

.dept-wrapper .footer {
  background: #c20315 !important;

  text-align: center;
  padding: 0.5rem 0;
}

.dept-wrapper .footer p {
  color: #fff;
  margin-bottom: 0px;
}

.head_of_dept {
  margin: 10% 0%;
}

.dept-wrapper .head_of_dept h6 {
  color: #c20315;
  margin-top: 2%;
}

.dept-wrapper header {
  margin-bottom: 2%;
}

.dept-wrapper .dept-title {
  border-bottom: 0;
}

.dept-wrapper .dept-title a {
  color: #000;

  font-size: 1.5em;
}

.dept-wrapper span.highlight {
  color: #c20315;
  font-weight: 500;
}

.dept-wrapper .tab-content a {
  font-weight: 500;
  color: #000;
}



.dept-wrapper .tab-content p:hover a {
  text-decoration: none;
  color: #c20315;
}

.dept-wrapper hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dept-wrapper .list_items_section li {
  color: #6c757d !important;
}

.dept-wrapper .copyright p {
  text-align: center;
}

/*Cards CSS Start*/

.dept-wrapper .card {
  /*transition:0.5s;*/
  background-color: transparent !important;
  /*cursor: pointer;*/
  /* padding: 5%;*/
  /*border: 2px solid #ddd;*/
  /*box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);*/
  border-radius: 0.5rem;
  margin-bottom: 2%;
  border: 0px;
}

.dept-wrapper .card:hover {
  /* border: 2px solid #c20315;*/
}

.dept-wrapper .tab-content .card {
  padding: 0px;
  border: 0px;
}

.dept-wrapper .card::before,
.dept-wrapper .card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform 0.3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: "";
  pointer-events: none;
}

.dept-wrapper .card::before {
  transform-origin: left top;
}

.dept-wrapper .card::after {
  transform-origin: right bottom;
}

.dept-wrapper .card:hover::before,
.dept-wrapper .card:hover::after,
.dept-wrapper .card:focus::before,
.dept-wrapper .card:focus::after {
  transform: scale3d(1, 1, 1);
}

/*Cards CSS End*/

.dept-wrapper header h1 {
  color: #000;
  font-weight: 600;
  font-size: 36px;
  /* color: #1aa3dd; */
  line-height: 50px;
  margin-bottom: 50px;
  /*margin-top: 40px;*/
}

.dept-wrapper span.bold {
  color: #000;
  font-weight: 600;
}

.dept-wrapper .dropdown button {
  background: no-repeat;
  color: #fff;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
}

.dept-wrapper .dropdown {
  position: relative;
  display: inline-block;
}

.dept-wrapper .dropdown-content {
  padding: 10%;
  text-align: left;

  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dept-wrapper .dropdown-content a {
  font-weight: 600;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dept-wrapper .dropdown-content a:hover {
  color: #c20315;
}

.dept-wrapper .dropdown:hover .dropdown-content {
  display: block;
}

.dept-wrapper .card-block h5 {
  color: #c20315;
  font-size: 20px;
  font-weight: 600;
}

/*.account-details-section p{color: #000;}*/

.dept-wrapper .main-site-section a {
  color: #808080;
}

.dept-wrapper .alumni-services-section a:hover {
  color: #c20315;
  font-weight: 600;
}

.dept-wrapper span.highlight {
  color: #c20315;
  font-weight: 600;
}

.dept-wrapper .event-img img {
  margin-bottom: 5%;
}

/* Faculty profile Css Start */

.dept-wrapper #profile-info {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 3;
}

.dept-wrapper #profile-info .card {
  padding: 0% 6% 0% 0% !important;

  border-radius: 0px;
  box-shadow: none;
  border: 0px;
}

.dept-wrapper #profile-info .card:nth-of-type(3n) {
  margin-right: 0;
}

.dept-wrapper #profile-info img {
  /* width: 100%;
    min-height:100%;*/

  /*width: 192px;
  height: 192px;*/
  border: 5px solid #edc512 !important;
  /* border: 5px solid #ddf2f3; */
  /*padding: 0.25rem;*/
  /* transition:all 0.9s*/
}

.dept-wrapper #profile-info .card:hover img {
  /*transform:scale(1.2,1.2)*/
}

.dept-wrapper #profile-info .data {
  /*position: absolute;*/
  bottom: 0%;
  /* background: #fff; */
  text-align: left;
  width: 100%;
  /*height: 360px;*/
  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
  /*padding: 10px 0px;*/
  box-sizing: border-box;
  /*opacity: 0.8;*/
  /*transition: all 0.4s*/
}

.dept-wrapper #profile-info .data h2 {
  margin: 0 0 5px 0;
  transition: color 0.4s;
}

.dept-wrapper #profile-info .data p {
  /*opacity: 0;*/
  text-align: left;
  transition: all 0.2s;
}

/*#award .card:hover .data p,.card:hover .data a {
    font-size: 12px;
    opacity: 1
  }*/

  .dept-wrapper #profile-info .data h5 {
  font-size: 18px;

  margin-bottom: 0px;
  color: #c20315;
  font-weight: 600;
}

.dept-wrapper #profile-info .data span {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 23.5px;
}

.dept-wrapper #profile-info .data h5 a {
  color: #1029eb ;
  text-decoration: none;
  /*padding:20px;*/
  /*opacity:0*/
}

.dept-wrapper #profile-info .data p.contact-info {
  margin-top: 0.5rem;
}

.dept-wrapper #profile-info .data p.contact-info a {
  color: #808080;
  font-weight: normal;
}

.dept-wrapper #profile-info .data p.contact-info a:hover {
  color: #000;
}

.dept-wrapper #profile-info .data a:hover,
.card:hover h2 {
  color: #000;
}

.dept-wrapper #profile-info .profile-info-individual {
  margin-bottom: 5%;

}

@media (max-width: 799px) {
  .dept-wrapper #profile-info .card {
    padding: 5% 0%;
    width: 100%;
    margin-right: 0;
  }
}

/* Faculty profile Css End */

/*Accordian CSS*/

.dept-wrapper .acc h5.card-title {
  margin-bottom: 0px;
}

.dept-wrapper .acc h3 {
  font-size: 16px;
  background-color: #f2f2f2;
  color: #000;
  padding: 15px;
  margin: 0;
  cursor: pointer;
  position: relative;
}

.dept-wrapper .acc h3:after {
  content: '+';
  position: absolute;
  right: 20px;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.dept-wrapper .acc.active h3:after {
  content: '-';
}

.dept-wrapper .acc .content {
  background-color: #fff;
  display: none;
}

.dept-wrapper .acc .content-inner {
  padding: 5% 5%;
  background: #fdfdfd;
}

.dept-wrapper .acc {
  border-bottom: 1px solid #fff;
}



/* server details card*/
.dept-wrapper .details-card {
/*   background: #ffffff; */
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);*/
  border-radius: 15px;
  padding: 10px 20px 10px 0px;
  /*height: 100%;*/
  display: flex;
  flex-direction: column;
}


/* Academic and laboratory facilities */

.dept-wrapper .academic-programmes .academic-programmes-individual,
.dept-wrapper .laboratory-facilities .laboratory-facilities-individual {
  margin-bottom: 5%;
}

.dept-wrapper .ul-check {
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.5em;
  padding-left: 1.5em;
}

.dept-wrapper .card-title {
  color: #000;
  font-size: 20px;
}

.dept-wrapper .text-black {
  font-weight: 400;

}

.dept-wrapper .item-card {
  /*transition: 0.5s;
  cursor: pointer;*/
}

.dept-wrapper .item-card-title {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
}

.dept-wrapper .item-card-title i {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
  color: #ffa710
}

.dept-wrapper .card-text {
  height: 80px;
}

.dept-wrapper .card::before,
.dept-wrapper .card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
}

.dept-wrapper .card::before {
  transform-origin: left top;
}

.dept-wrapper .card::after {
  transform-origin: right bottom;
}

.dept-wrapper header h3 {
  margin-bottom: 3%;
}


.dept-wrapper p.get_in_touch {
  margin-top: 5%;
  padding: 2% 0%;
  border-top: 1px solid #bc4028;
  border-bottom: 1px solid #bc4028;
}

.dept-wrapper a.more-info-link {
  /*margin-top: 5%;*/
  /*background-color: #eaeaea;*/
  color: #666;
  /*-webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,.25);
                          box-shadow: inset 0 -2px 0 rgba(0,0,0,.25);*/
  /*padding: 12px 20px;*/
}


.dept-wrapper a.more-info-link:hover {

  color: #c20315 !important;
}


.dept-wrapper .vision-mission-section {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 2%;
}


.dept-wrapper .image {
  border: 5px solid #edc512;
  /* padding: 0.25rem;*/
}

/*.image:hover img{
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}*/


.dept-wrapper .academic_group .card {
  margin-bottom: 10%;
}

.dept-wrapper .academic_group a {
  font-weight: normal;
  color: #808080;
}


.dept-wrapper .nav-tabs .nav-item.show .nav-link,
.dept-wrapper .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #c20315;
  border: none;
  margin-bottom: 2px;
}

/*.nav-tabs .nav-link.active::before {
border-color: #0093b7 transparent transparent transparent;
border-style: solid;
border-width: 20px 50px 0px 50px;
content: "";
display: block;


position: absolute;
width: 0px;
top: 40px;
z-index: 10;



}*/

.dept-wrapper .nav-tabs .nav-link.active:after {
  content: '';
  height: 0;
  width: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #c20315;
  left: 0;
  right: 0;
  top: 25%;
  margin: auto;
  position: relative;
  display: block;
}




.dept-wrapper .nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: #00b6e2;
  border: none;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
  margin-left: 1%;
  margin-bottom: 2px;
}





.dept-wrapper .nav-tabs {
  border-bottom: none;
}


.dept-wrapper .intro-section {
  margin-bottom: 5%;
}

.dept-wrapper .intro-section p {
  text-align: justify;
}

.dept-wrapper .vision-section,
.dept-wrapper .mission-section {
  margin-top: 2%;
}


.dept-wrapper .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00b6e2;
  border-color: #00b6e2;
}

.dept-wrapper .head_of_dept img {
  border: 5px solid #edc512;
}


.dept-wrapper .notable-alumni .card a img,
.dept-wrapper .std-society .card a img {
  border: 5px solid #ddf2f3;
}

.dept-wrapper .notable-alumni .data h6,
.dept-wrapper .std-society .data h6 {
  margin-bottom: 0px;
}

.dept-wrapper .notable-alumni .data span,
.dept-wrapper .std-society .data span {
  font-weight: 400;
  font-size: 14px;
  color: #808080;

}


.dept-wrapper .std-society .data {
  /* position: absolute; */
  bottom: 0%;
  /* background: #fff; */
  text-align: left;
  width: 100%;
  /* height: 360px; */
  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 0px;
  box-sizing: border-box;
  /* opacity: 0.8; */
  /* transition: all 0.4s; */
}



.dept-wrapper .notable-alumni .data {
  /* position: absolute; */
  bottom: 0%;
  /* background: #fff; */
  text-align: left;
  width: 100%;

  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 0px;
  box-sizing: border-box;
  /* opacity: 0.8; */
  /* transition: all 0.4s; */
}


.dept-wrapper .section .card-4-container div:last-child .card-4 {
  margin-bottom: 0;
}


.dept-wrapper .card-4 {
  /*border-left: 5px solid #00b6e2;*/
  margin: 0 0px 30px 0;

}


.dept-wrapper .card-4 .card-detail {
  /* background: #fff; */
  padding: 0;
  position: relative;
}


.dept-wrapper .card-detail {
  /* background: #0c94e2; */
  padding: 20px;
  color: #808080;
}


.dept-wrapper .card-4 .card-detail .head-bx {
  /*min-height: 20rem;
  max-height: 20rem;*/
  padding: 15px 0px;
}


.dept-wrapper .card-4 .card-detail .info-bx {
  /*padding: 15px 30px;*/
  /*min-height: 14rem;
  max-height: 14rem;*/
}


.dept-wrapper .card-4 .card-detail .info-bx {
  /*background: #f6f6f6;
  padding: 30px 35px;*/
  color: #000000;

}


.dept-wrapper .card-4 .card-detail .more {
  /*background: #00b6e2;*/
  text-align: left;
  margin-top: 0;
  display: flex;
}


.dept-wrapper .card-detail .more {
  text-align: right;
  font-size: 14px;
  margin: 10px 0 0;
}


.dept-wrapper .card-4 .card-detail .more a {
  margin-top: 0;
  text-decoration: none;
  font-size: 18px;
  /*padding: 20px 0px;*/

  line-height: 2;
  color: #c20315;
  font-weight: normal;
  /*text-align: center;*/
}


.dept-wrapper .card-4 .card-detail .more a:hover {
  color: #000 !important;
}




/*start phd students css */

.dept-wrapper .phd-std-img img {
  transition: all .2s ease-in-out;
  overflow: hidden;
  width: 100%;
}

.dept-wrapper .phd-std-member,
.dept-wrapper .phd-std-img {
  position: relative;
  /*overflow: hidden;*/
}

.dept-wrapper .std-title {
  margin: 10px 0 0px;
      color: #1029eb;
}

.dept-wrapper .overlay {
  background-color: rgba(20,20,20,.7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .2s ease-in-out;
}

.dept-wrapper .std-details {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 5%;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  transition: all .2s ease-in-out;
}

.dept-wrapper .std-details p {
  color: #fff;
}

.dept-wrapper .phd-std-img:hover .std-details {
  opacity: 1;
  margin-top: -80px;
}

.dept-wrapper .phd-std-img:hover .overlay {
  opacity: 1;
}

.dept-wrapper .phd-std-member span{
font-size:13px;
}

/*end phd students css */



.alumniTabHide {
  display: none !important;
}

.tab-bold {
  font-weight: 700 !important;
}

.nav-link:hover {
  cursor: pointer;
}


.dept-wrapper .details {
  padding: 5px 0 25px 0;
  margin-bottom: 2%;
}

.dept-wrapper .details span {
 /* font-size: 13px;*/
  color: #c20315;
  font-weight: 600;
}

.dept-wrapper .details h4 {
  line-height:1.2;
  color: #000;
}

ul.awards-ul{list-style-type: disc !important;}
.awards-ul li{margin-bottom: 2%;}







@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .dept-wrapper .intro-section p {
    text-align: left;
  }

  .dept-wrapper .vision-section,
  .dept-wrapper .mission-section {
    margin-top: 10%;
  }


  .dept-wrapper .academic-programmes .academic-programmes-individual,
  .dept-wrapper .laboratory-facilities .laboratory-facilities-individual {
    margin-bottom: 15%;
  }

  .dept-wrapper .head_of_dept {
    border-bottom: 2px solid gray;
  }

  .dept-wrapper h3{    
    font-size: 20px;
    line-height: 1.3;
  }


  p.contact-info{
    font-size: 12px;
    line-height: 1.5;
  }

  .std-details p{
    font-size: 12px;
    line-height: 1.5;
  }

}

.notice-board1 {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c5f2e0;
  border: 1px solid #4CAF50;
  color: rgb(184, 9, 36) !important;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.notice-board1:hover {
  background-color: #F1614B;
  color: white !important;
}

.notice-board1 a{
  color: rgb(184, 9, 36) !important;;
}
.notice-board1 a:hover{
  background-color: #F1614B;
  color: white !important;
}

.listview {
  margin: 0;
  padding: 0;
}
.listview p{
  margin-bottom:0;

}
.listitem {
  position: relative;
  padding-left: 20px; /* Adjust padding to give space for the "bullet" */
  padding-bottom: 0;
  margin: 0;

}

.listitem::before {
  content: '•'; /* Use a bullet or any other character as the list marker */
  position: absolute;
  left: 0;
  top: 0;
  color: #000; /* Bullet color */
  font-size: 1.em; /* Adjust size of the bullet */
  /* line-height: 1; Align bullet with text */
}


.table tr:first-child td {
  font-weight: normal;
}

/* Additional styles for circular tabs */
.circle-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background-color: #f8f8f8;
  border-radius: 150%;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 70px; /* Add your desired left margin */
  margin-right: 20px; /* Add your desired right margin */
  margin-bottom: 40px; /* Increase the margin between tabs */
}


/* Equal margin between Chief Guest, Notice, and Registration */
.circle-tab.chief-tab,
.circle-tab.notice-tab,
.circle-tab.registration-tab {
  margin-bottom: 1rem; /* Adjust this value as needed */
}


/* Rest of the CSS rules... */

.circle-tab.active {
  transform: scale(1.1);
}

.circle-tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;

}


.circle-tab-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s;
}

.circle-tab-label {
  margin-top: 5px;
  font-size: 12px;
  transition: color 0.3s;
}

.hovered .circle-tab {
  background-color: #f0f0f0;
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.hovered .circle-tab-image {
  transform: scale(1.2);
}

/* Decrease gap between Notice and Registration */
.circle-tab.notice,
.circle-tab.registration {
  margin-bottom: 1rem; /* Adjust this value as needed */
}

/* Increase gap between Chief Guest and Notice */
.circle-tab.guest {
  margin-bottom: 5rem; /* Adjust this value as needed */
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .circle-tab.chief-tab,
  .circle-tab.notice-tab,
  .circle-tab.registration-tab {
    margin-bottom: 1rem; /* Adjust this value as needed */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
  }

  /* Rest of the media query styles... */
}
/* Media Query for Mobile Devices */
@media (max-width: 422px) {
  .youtube-section {
    padding: 10px; /* Adjust padding for mobile */
  }

  .youtube-content {
    flex-direction: column;
    align-items: flex-start; /* Align content to the left for mobile */
  }

  .left-column, .right-column {
    flex: auto; /* Take full width on mobile */
    margin: 0;
    text-align: left;
  }

  .right-column {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .youtube-embed iframe {
    width: 100%; /* Adjust width to fit the container on mobile */
    height: auto; /* Let height adjust based on width */
  }
  .custom-paragraph1 {
    /* Add your additional styles here*/
    /* font-size: 90% !important; */
  } 
}


/* Define a consistent font style */
/* .bodyd {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333; 
} */

/* Define primary colors */


/* Style for the member cards */
/* .member {
  background-color: #fff;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  padding: 20px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
} */

/* Style for the card when hovered */
/* .member:hover {
  background-color: var(--primary-bg-color);
  color: white;
 
} */

/* Style for the "PROGRAM SCHEDULE" heading */
h4 {
  font-size: 24px;
  /* color: var(--primary-bg-color); */
  /* margin-top: 30px; */
}

/* Style for the table */
/* table-founddation {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  border: 2px solid var(--border-color);
} */

/* Style for table cells */
/* table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
} */

/* Style for the YouTube Live Streaming section */
.youtube-section {
  /* background-color: var(--secondary-bg-color); */
  padding: 20px;
  margin-top: 40px;
}

.youtube-section h3 {
  /* font-size: 24px;
  color: var(--primary-bg-color); */
  margin-bottom: 10px;
}


.chief-guest-details{
  margin-top: 5%;
      margin-right: 5%;
  }
  .chief-guest-details p{
  font-weight: 400 !important;
  font-size: 16px !important;
  }

/* Media Query for Mobile Devices */
@media (max-width: 422px) {
  .youtube-section {
    padding: 10px;
  }

  .youtube-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .left-column, .right-column {
    flex: auto;
    margin: 0;
    text-align: left;
  }

  .right-column {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .youtube-embed iframe {
    width: 100%;
    height: auto;
  }
}

/* Style for the Chief Guest section */
.chief-guest-section {
  display: flex;
  max-width: 700px;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Style for the Chief Guest details */
.chief-guest-details {
  flex: 1 1;
}

/* Style for the Chief Guest name */
.chief-guest-details h2 {
  font-size: 34px;
  margin-bottom: 2px;
}

/* Style for the Chief Guest position */
.chief-guest-details p {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Style for the Chief Guest message */
.chief-guest-details p:last-child {
  font-style: italic;
}

/* Style for the Chief Guest photo */
.chief-guest-image {
  width: 220px;
  height: 220px;
  border-radius: 10px;
  margin-right: 5px;
  overflow: hidden;
}

/* Style for the Chief Guest image */
.chief-guest-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Media query for screens with a maximum width of 768px */
@media screen and (max-width: 768px) {
  .chief-guest-section {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  /* Adjust styles for the Chief Guest image on mobile */
.chief-guest-image {
  width: 250px;
  height: 250px;
  margin-left: 55px;
}
}

/* DeptBrochurePage.css */

/* Specific styles for DeptBrochurePage */
.dept-brochure-page {
    text-align: center;
    background-color: #f5f5f5; /* Light gray background color */
    padding: 20px; /* Add some padding for better spacing */
}

.dept-brochure-page .row {
    display: flex;
    flex-wrap: wrap;
}

.dept-brochure-page .col-sm-6 {
    flex: 0 0 45%; /* Decreased the max-width to 45% */
    max-width: 45%;
}

.dept-brochure-page .col-md-3 {
    flex: 0 0 20%; /* Decreased the max-width to 20% */
    max-width: 20%;
}

.dept-brochure-page .mb-4 {
    margin-bottom: 1rem;
}

.dept-brochure-page .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Lighter border color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for box shadow */
}

.dept-brochure-page .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly larger box shadow on hover */
}

.dept-brochure-page .exam-small-font {
    font-size: 14px;
    color: #777; /* Slightly darker text color */
}

.dept-brochure-page .hovered {
    /* Add your hover styles here */
}

.dept-brochure-page .rounded-image {
    display: inline-block;
    width: 100%; /* Full width for the image */
    height: auto;
    border-radius: 8px 8px 0 0; /* Rounded corners only at the top */
}

.dept-brochure-page .card-content {
    padding: 10px; /* Add padding for better spacing */
}

/* Media query for screens with a maximum width of 420 pixels */
@media (max-width: 420px) {
    .dept-brochure-page .col-md-3,
    .dept-brochure-page .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* AcademicCalendarPage.css */

/* Specific styles for AcademicCalendarPage */
.academic-calendar-page {
    text-align: center;
    background-color: #f5f5f5; /* Light gray background color */
    padding: 20px; /* Add some padding for better spacing */
}

.academic-calendar-page .row {
    display: flex;
    flex-wrap: wrap;
}

.academic-calendar-page .col-sm-6 {
    flex: 0 0 40%; /* Decreased the max-width to 40% */
    max-width: 40%;
}

.academic-calendar-page .col-md-3 {
    flex: 0 0 20%; /* Decreased the max-width to 20% */
    max-width: 20%;
}

.academic-calendar-page .mb-4 {
    margin-bottom: 1rem;
}

.academic-calendar-page .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Lighter border color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for box shadow */
}

.academic-calendar-page .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly larger box shadow on hover */
}

.academic-calendar-page .exam-small-font {
    font-size: 14px;
    color: #777; /* Slightly darker text color */
}

.academic-calendar-page .hovered {
    /* Add your hover styles here */
}

.academic-calendar-page .rounded-image {
    display: inline-block;
    width: 100%; /* Full width for the image */
    height: auto;
    border-radius: 8px 8px 0 0; /* Rounded corners only at the top */
}

.academic-calendar-page .card-content {
    padding: 10px; /* Add padding for better spacing */
}

/* Media query for screens with a maximum width of 420 pixels */
@media (max-width: 420px) {
    .academic-calendar-page .col-md-3,
    .academic-calendar-page .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
/* ExamBranchPage.css */

/* Specific styles for ExamBranchPage */
.exam-branch-page {
    text-align: center;
    /* font-family: 'Times New Roman', Times, serif */
  }
  

  .exam-branch-page .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .exam-branch-page .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .exam-branch-page .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .exam-branch-page .mb-4 {
    margin-bottom: 1rem;
  }
  
  .exam-branch-page .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  /* ExamBranchPage.css or your custom styles */
  .exam-small-font {
  font-size: 14px; /* Adjust the font size as needed */
}
  /* .exam-branch-page .hovered {
    /* Add your hover styles here 
  } */
  
  .exam-branch-page .rounded-image {
    display: inline-block;
    max-width: 70%; /* increase and decrease size of box */
    height: auto;
    /* Additional styles for the rounded image if needed */
  }
  
  .exam-branch-page .card-content {
    margin-top: 2px;
  }
  /* Media query for screens with a maximum width of 420 pixels */
@media (max-width: 420px) {
    .exam-branch-page .col-md-3,
    .exam-branch-page .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
/* Careers.css */

/* Add this CSS to your existing Careers.css file or create a new one */
.border-btn1 {
    border: 1px solid #333;
  }
  
  .border-tab1 {
    border: 1px solid #ccc;
    padding: 10px;
    display: block;
    text-decoration: none;
  }
  
  /* Add the following CSS to ensure the button remains visible on hover */
  .border-btn1:hover {
    border: 1px solid red;
    transition: none; /* Remove transition on hover */
  }
  
  /* Add this CSS to ensure the tab remains visible on hover */
  .border-tab:hover {
    border: 1px solid red;
    transition: none; /* Remove transition on hover */
  }
  
  /* Add the following CSS for the slide-in effect */
  .slide-in {
    animation: slideIn 0.5s ease-out;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Add this CSS for the creative card styling */
  .creative-card1 {
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px;
  }
  
  .creative-card1 .image {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
  }
  
  .creative-card1 .overlay1 {
    border-radius: 0 0 5px 5px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s ease-out;
  }
  
  /* .creative-card:hover .overlay {
    background-color: rgba(255, 255, 255, 0.7);
  } */
  
  .creative-card1 .text {
    text-align: left;
  }
  
  .creative-card1 h4 {
    font-weight: bold;
    color: black;
    margin: 10px 0;
    max-height: 70px;
    overflow: hidden;
  }
  
  .creative-card1 p {
    text-align: justify;
    color: #555;
    font-size: 0.9em;
  }
  
  .creative-card1 .cource-btn {
    border: 1px solid red;
    border-radius: 5px;
    display: inline-block;
    padding: 8px;
    color: red;
    text-decoration: none;
    transition: none;
    text-align: center;
  }
  
   .creative-card1 .cource-btn:hover {
    background-color: red;
    color: white;
  } 
  
/* FacultyPositions.css */

.page {
    background-color: #f5f5f5; /* Set the background color */
}
  
.tabs {
    margin-bottom: 0px; /* Adjust the margin-bottom to reduce the gap between tabs and content */
  
}
  
.tabs button {
    background-color: #4caf50; /* Set the tab background color */
    color: white; /* Set the tab text color */
    margin-right: 10px; /* Adjust the margin-right to reduce the gap between tabs */
}
  
.tabs button.active {
    background-color: #45a049; /* Set the active tab background color */
}
  
.tab-content {
    /* margin-top: -25px;  */
    /* Adjust the margin-top to reduce the gap between tabs and content */
    
}

/* Remove margin and padding for ul and li */
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    margin: 0;
    padding: 0;
}

/* Reduce the gap between rows */
hr {
    margin: -32px 0;
}

element.style {
}
.static-facilities .tabs button.active {
    background-color: #0dcaf0;
    color: #fff;
}



a {
    /* color: #007bff;  */
    text-decoration: none; /* Remove underlines from links */
    transition: color 0.3s ease; /* Add a smooth transition effect */
}

a:hover {
    color: #0056b3; /* Change link color on hover */
    /* text-decoration: underline;  Add underline on hover */
}

.static-facilities .tabs button 

{
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    background-color: #D6EAF8;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s
}
.profile-card{
/*     min-height: 355px  */
    min-height: 460px;
}

/* kdd.css */

/* Specific styles for KeyDocuments */
.key-documents-page {
  /* Add your styles here */
}

.key-documents-page .row {
  display: flex;
  flex-wrap: wrap;
}

.key-documents-page .col-sm-4 {
  flex: 0 0 20%; /* Set the flex basis to 20% for 5 images in a row */
  max-width: 20%;
}

.key-documents-page .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Lighter border color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for box shadow */
}

.key-documents-page .card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly larger box shadow on hover */
}

.key-documents-page .rounded-image {
  display: inline-block;
  width: 100%; /* Full width for the image */
  height: auto;
  border-radius: 8px 8px 0 0; /* Rounded corners only at the top */
}
/* Updated MilestonesTimeline.css */
.milestones-container {
    padding: 20px;
    background-color: #f0f0f0;
}

.milestones-title {
    text-align: center;
    color: blue;
}

.milestones-list {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: center;*/  /* Center align milestones */
    grid-gap: 18px;
    gap: 18px; /* Set gap between milestones */
}

.milestone-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    padding: 3%;
    margin: 0 10px 20px 10px; /* Set margin for spacing */
    /* background-color: #014cf7; */
    border-radius: 20px;
    position: relative;
}



.milestone-box:nth-child(odd) {
    background-color: #2b4675; 
}

.milestone-box:nth-child(even) {
    background-color: #8fc0c4; 
}



.milestone-content {
    margin-top: 10px;
}

.milestone-date {
    font-weight: bold;
    color: #fff;
    font-size: 20px;
}

.milestone-description {
    color: #fff;
}

.arrow {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #787878;
}

.connector {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background-color: #787878;
    width: calc(100% - 335px);
}
/* .paragraph-container {
    max-width: 65%;
    margin-top: 10px;
    text-align: justify;
} */



.nav-tabs .about-tab.active{
    color: #ffffff;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #f19800 !important;
}

.nav-tabs{border-bottom: none !important;}



.tab-title-bar{
    background: #88923e;
      padding: 2px 45px;
      /* position: absolute; */
      top: 0;
     
      left: 0;
      height: 76px;
      display: flex;
      align-items: center;
  }
  
  
  
  
  .aboutus-tabs li.nav-item a{
    font-size: 24px !important;
    color: #828282;
  }
  
  .nav-tabs .about-tab{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }


/* RANKING */
.nirf-ranking-counter [class^=col-] {
    padding: 33px 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.1); }
    .nirf-ranking-counter [class^=col-]:last-child {
      border-right: 0px; }
  
  
  
  
  
  
  /* CARDS */
  
  h2.ccpd_header{font-size: 1.8rem;color: #c20315;font-family: 'Lato', sans-serif;font-weight: bold;}
  .placements-stats h5 a{font-weight: bold !important; color:#c20315 !important ;}
  .placements-stats h5 a:hover{font-weight: bold !important; color:#000 !important ;}
  .placements-stats hr{border-bottom: 1px solid #dedede;}
  
  
  .nitw_ccpd-title {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  color: #444;
  margin-bottom: 60px;
  line-height: 1;
  }
  .nitw_ccpd-card {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* background: #edf2ff; */
  border: 1px solid transparent;
  transition: all 0.6s ease;
  transform: perspective(1000px);
  }
  
  
  .nitw_ccpd-card-top {
  transition: all 0.6s ease;
  border-radius: 15px;
  background: var(--card-color);
  position: relative;
  /* padding: 20px 30px; */
  color: #1b4cc8;
  display: flex;
  flex-direction: column;
  transform: perspective(1000px);
  margin-bottom: 2%;
  }
  h3.nitw_ccpd-card-title {
  font-weight: 700;
  font-size: 24px;
  color: #1b4cc8;
  /* margin-bottom: 80px; */
  }
  
  
  .nitw_ccpd-card-body {
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  flex: 1 1;
  }
  .nitw_ccpd-card-body p{
  margin-top: 2%;
  }
  
  
  
  /* .nitw_ccpd-card_bg{
  --card-color: #3868e4;
  border: 1px solid  #3868e4;
  } */
  
  
  ul.offers-list{
    list-style-type: disc !important;
  }
  .individual-card{
    margin-bottom: 5%;
  }
  
  
  /* RECRUITMENT */
  
  ul.recruiters-list{
    columns: 3 10rem;
    list-style-type: disc !important;
  }
  
  
  /* Demographics */
  .chart-container {
  width: 100%;
  max-width: 600px;
  height: 500px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  
  
  /* fsfwsefewfrwerwe*/
  
  
  .tile{
  width: 100%;
  
  border-radius:5px;
  box-shadow:0px 2px 3px -1px rgba(151, 171, 187, 0.7);
  float:left;
    transform-style: preserve-3d;
    margin: 10px 5px;
  
  }
  
  .tile .header{
  border-bottom:1px solid #ebeff2;
  padding:19px 0;
  text-align:center;
  color:#59687f;
  font-size:600;
  font-size:19px;	
  position:relative;
  }
  
  
  
  
  .dates{
  border:1px solid #ebeff2;
  border-radius:5px;
  padding:20px 0px;
  margin:10px 20px;
  font-size:16px;
  color:#5aadef;
  font-weight:600;	
  overflow:auto;
  }
  .dates div{
  float:left;
  width:50%;
  text-align:center;
  position:relative;
  }
  .dates strong,
  .stats strong{
  display:block;
  color:#adb8c2;
  
  }
  .dates span{
  width:1px;
  height:40px;
  position:absolute;
  right:0;
  top:0;	
  background:#ebeff2;
  }
  .stats{
  border-top:1px solid #ebeff2;
  background:#f7f8fa;
  overflow:auto;
  padding:15px 0;
  font-size:16px;
  color:#59687f;
  font-weight:600;
  border-radius: 0 0 5px 5px;
  }
  
  
  .stats div:nth-of-type(3){border:none;}
  
  .progress{
  background-color: #dddcdc;
  }
  
  
  
  /* rounded*/
  
  .rounded-progress{
    width: 120px;
    height: 120px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    margin-bottom: 10%;
  }
  .rounded-progress:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
  }
  .rounded-progress > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .rounded-progress .rounded-progress-left{
    left: 0;
  }
  .rounded-progress .rounded-progress-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
  }
  .rounded-progress .rounded-progress-left .rounded-progress-bar{
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    transform-origin: center left;
  }
  .rounded-progress .rounded-progress-right{
    right: 0;
  }
  .rounded-progress .rounded-progress-right .rounded-progress-bar{
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
  }
  .rounded-progress .rounded-progress-value{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #dddcdc;
    font-size: 18px;
    font-weight: bold;
    color: #0d6efd;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 0%;
    left: 5%;
  }
  .rounded-progress.green .rounded-progress-bar{
    border-color: #2b7238;
  }
  .rounded-progress.green .rounded-progress-left .rounded-progress-bar{
    animation: loading-2 1.5s linear forwards 1.8s;
  }
  .rounded-progress.light .rounded-progress-bar{
    border-color: #2dd897;
  }
  .rounded-progress.light .rounded-progress-left .rounded-progress-bar{
    animation: loading-3 1s linear forwards 1.8s;
  }
  .rounded-progress.pink .rounded-progress-bar{
    border-color: #ed687c;
  }
  .rounded-progress.pink .rounded-progress-left .rounded-progress-bar{
    animation: loading-4 0.4s linear forwards 1.8s;
  }
  .rounded-progress.green .rounded-progress-bar{
    border-color: #135607;
  }
  .rounded-progress.green .rounded-progress-left .rounded-progress-bar{
    animation: loading-5 1.2s linear forwards 1.8s;
  }
  @keyframes loading-1{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(180deg);
    }
  }
  @keyframes loading-2{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(144deg);
    }
  }
  @keyframes loading-3{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(90deg);
    }
  }
  @keyframes loading-4{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(36deg);
    }
  }
  @keyframes loading-5{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(126deg);
    }
    
  }
  @media only screen and (max-width: 990px){
    .rounded-progress{ margin-bottom: 20px; }
  }
  
  .progress-seperator{border-right: 2px solid gray;}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

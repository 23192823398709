.chartsDisplay{
    display: flex;
    justify-content: center;
    /* color: maroon; */
}

@media screen and (max-width:780px) {
    .chartsDisplay{
        display: block;
        padding: 0;
    }
}
@media screen and (min-width:1800px) {
    .chartsDisplay{
        display: inline-block;
        margin-left: 17vw;
    }
}

/* @media screen and (min-width:1800px) {
    .charts{
        width: 15vw;
        height: 15vh;
    }
} */
.notice-board {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c5f2e0;
  border: 1px solid #4CAF50;
  color: rgb(184, 9, 36) !important;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.notice-board:hover {
  background-color: #F1614B;
  color: white !important;
}

.nav-item.btn{
  padding: 5px !important;
}
.textc{
  color: #2b4675;
}


.notice-board-section{
  margin: 2% 0%;
}

.notice-board-section ul li:hover a{
color: #fff;
}

.notice-board-section ul li.btn-outline-danger{
  border-color:#2b4675;
}

.btn-outline-danger:hover{
  background-color: #2b4675;
}
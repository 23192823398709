/* html ::-webkit-scrollbar {
  display: none;
} */
.announcement-wrapper .text-start {
  overflow: hidden;
}


.announcement-wrapper .text-start:hover {
  overflow-y: scroll;

}

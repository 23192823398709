/* Define a consistent font style */
/* .bodyd {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333; 
} */

/* Define primary colors */


/* Style for the member cards */
/* .member {
  background-color: #fff;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  padding: 20px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
} */

/* Style for the card when hovered */
/* .member:hover {
  background-color: var(--primary-bg-color);
  color: white;
 
} */

/* Style for the "PROGRAM SCHEDULE" heading */
h4 {
  font-size: 24px;
  /* color: var(--primary-bg-color); */
  /* margin-top: 30px; */
}

/* Style for the table */
/* table-founddation {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  border: 2px solid var(--border-color);
} */

/* Style for table cells */
/* table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
} */

/* Style for the YouTube Live Streaming section */
.youtube-section {
  /* background-color: var(--secondary-bg-color); */
  padding: 20px;
  margin-top: 40px;
}

.youtube-section h3 {
  /* font-size: 24px;
  color: var(--primary-bg-color); */
  margin-bottom: 10px;
}


.chief-guest-details{
  margin-top: 5%;
      margin-right: 5%;
  }
  .chief-guest-details p{
  font-weight: 400 !important;
  font-size: 16px !important;
  }

/* Media Query for Mobile Devices */
@media (max-width: 422px) {
  .youtube-section {
    padding: 10px;
  }

  .youtube-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .left-column, .right-column {
    flex: auto;
    margin: 0;
    text-align: left;
  }

  .right-column {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .youtube-embed iframe {
    width: 100%;
    height: auto;
  }
}

/* Style for the Chief Guest section */
.chief-guest-section {
  display: flex;
  max-width: 700px;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Style for the Chief Guest details */
.chief-guest-details {
  flex: 1;
}

/* Style for the Chief Guest name */
.chief-guest-details h2 {
  font-size: 34px;
  margin-bottom: 2px;
}

/* Style for the Chief Guest position */
.chief-guest-details p {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Style for the Chief Guest message */
.chief-guest-details p:last-child {
  font-style: italic;
}

/* Style for the Chief Guest photo */
.chief-guest-image {
  width: 220px;
  height: 220px;
  border-radius: 10px;
  margin-right: 5px;
  overflow: hidden;
}

/* Style for the Chief Guest image */
.chief-guest-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Media query for screens with a maximum width of 768px */
@media screen and (max-width: 768px) {
  .chief-guest-section {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  /* Adjust styles for the Chief Guest image on mobile */
.chief-guest-image {
  width: 250px;
  height: 250px;
  margin-left: 55px;
}
}

/* Additional styles for circular tabs */
.circle-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background-color: #f8f8f8;
  border-radius: 150%;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 70px; /* Add your desired left margin */
  margin-right: 20px; /* Add your desired right margin */
  margin-bottom: 40px; /* Increase the margin between tabs */
}


/* Equal margin between Chief Guest, Notice, and Registration */
.circle-tab.chief-tab,
.circle-tab.notice-tab,
.circle-tab.registration-tab {
  margin-bottom: 1rem; /* Adjust this value as needed */
}


/* Rest of the CSS rules... */

.circle-tab.active {
  transform: scale(1.1);
}

.circle-tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;

}


.circle-tab-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s;
}

.circle-tab-label {
  margin-top: 5px;
  font-size: 12px;
  transition: color 0.3s;
}

.hovered .circle-tab {
  background-color: #f0f0f0;
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.hovered .circle-tab-image {
  transform: scale(1.2);
}

/* Decrease gap between Notice and Registration */
.circle-tab.notice,
.circle-tab.registration {
  margin-bottom: 1rem; /* Adjust this value as needed */
}

/* Increase gap between Chief Guest and Notice */
.circle-tab.guest {
  margin-bottom: 5rem; /* Adjust this value as needed */
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .circle-tab.chief-tab,
  .circle-tab.notice-tab,
  .circle-tab.registration-tab {
    margin-bottom: 1rem; /* Adjust this value as needed */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
  }

  /* Rest of the media query styles... */
}
/* Media Query for Mobile Devices */
@media (max-width: 422px) {
  .youtube-section {
    padding: 10px; /* Adjust padding for mobile */
  }

  .youtube-content {
    flex-direction: column;
    align-items: flex-start; /* Align content to the left for mobile */
  }

  .left-column, .right-column {
    flex: auto; /* Take full width on mobile */
    margin: 0;
    text-align: left;
  }

  .right-column {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .youtube-embed iframe {
    width: 100%; /* Adjust width to fit the container on mobile */
    height: auto; /* Let height adjust based on width */
  }
  .custom-paragraph1 {
    /* Add your additional styles here*/
    /* font-size: 90% !important; */
  } 
}


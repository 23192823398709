
@import url('https://fonts.googleapis.com/css?family=Nunito:100,300,400,600,700');
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");


.slide{border: 0px;}

.profile-card{
	background-color: #fff;
	padding: 0px;
    min-height: 415px;
}

.profile-card:hover{
	background-color: #fff;
	
}

.profile-card .card-img-block{
    float:left;
    width:100%;
    height: 100px;
    overflow:hidden;
	background-color: #ebebeb !important;
	padding: 0px !important;
}
.profile-card .card-body{
    position:relative;
	text-align: left;
}
.profile-card .profile {
  border-radius: 50%;
  position: absolute;
  top: -42px;
  left: 15%;
  max-width: 100px;
  border: 3px solid rgba(255, 255, 255, 1);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}
.profile-card h5{
    font-weight:600;
    color:rgb(184, 9, 36);
}
.profile-card .card-text{text-align: left !important;}
.profile-card .card-text{
    font-weight:300;
    font-size:15px !important;
	text-align: left;
}


.profile-card span {
    font-size:14px;
    background:#fff;
    border:1px solid rgb(184, 9, 36);
    color:rgb(184, 9, 36);
    display:inline-block;
    padding:2px 10px;
    border-radius:15px;
	text-align: left;
    
}

.profile-card .bottom-section{
    position: absolute;
    bottom: 6%;
}

.profile-card .seminar-info-section{
/*  position: absolute;
    top: -15px;
    right: 0%; */
    /* max-width: 100px; */
    /* border: 3px solid rgba(255, 255, 255, 1); */
/*     transform: translate(-10%, 0%); */
}

.profile-card .seminar-info-section p.date-info{
    font-size: 12px !important;
    font-weight: 500;
/*     text-align: center; */
}

.profile-card .bottom-section a{
   font-size: 14px !important;
   font-weight: 600;
   color:rgb(184, 9, 36)
}

.profile-card .bottom-section a:hover{
    font-size: 14px !important;
    font-weight: 600;
    color:#000
 }

.carousel-control-prev-icon,.carousel-control-next-icon{
	background-color: rgba(0,0,0,0.5);
	background-size: 50% 50%;
	width: 1.5rem;
    height: 1.5rem;
	visibility:hidden;
	border-radius: 50%;
}

.carousel-control-prev, .carousel-control-next{
    width: 9%;
}

#carouselExampleControls:hover .carousel-control-next-icon{
	visibility:visible;
}

#carouselExampleControls:hover .carousel-control-prev-icon{
	visibility:visible;
}
